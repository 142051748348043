import React, { useEffect, useRef, forwardRef } from "react";
import JsBarcode from "jsbarcode";
import logoLP from "../../../assets/img/logoLP.png";
import { secondary } from "../../../assets/colors"; // Ajusta según tus colores

// Define los colores específicos
const brown = '#8B4513';
const green = '#008000';
const red = '#FF0000';
const BarcodeMasive = forwardRef(({ selectedItems }, ref) => {
  const barcodeRefs = useRef([]);

  useEffect(() => {
    selectedItems.forEach((item, index) => {
      if (barcodeRefs.current[index] && item.codigoBarras) {
        JsBarcode(barcodeRefs.current[index], item.codigoBarras, {
          format: "CODE128",
          displayValue: false,
          width: 4.2, // Ajusta según tus necesidades
          height: 140, // Ajusta según tus necesidades
        });
      }
    });
  }, [selectedItems]);

  return (
    <div ref={ref} style={{
      transform: "scale(0.75)",
      padding: "0px",  
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",  // Centra verticalmente
      maxWidth: "110rem",  
      color: 'black',
      marginTop: "5px", 
    }}>
      {selectedItems.map((item, index) => (
        <div key={index} style={{ textAlign: "center", pageBreakBefore: index === 0 ? "auto" : "always" }}>
          {/* Centra horizontalmente */}
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            {logoLP && <img src={logoLP} alt="logo" width={120} />}
            {item.rucEmpresa && <div style={{ fontSize: '18pt', fontWeight: 'bold' }}>{item.rucEmpresa}</div>}
          </div>
          {item.codigoBarras && (
            <div style={{ marginTop: "-2px", zIndex: 2 }}>
              <span style={{ color: 'blue', fontSize: '24pt', fontWeight: 'bold', marginRight: '7px' }}>{item.codigoBarras.substring(0, 3)}</span>
              <span style={{ color: brown, fontSize: '24pt', fontWeight: 'bold', marginRight: '7px' }}>{item.codigoBarras.substring(3, 6)}</span>
              <span style={{ color: green, fontSize: '24pt', fontWeight: 'bold', marginRight: '7px' }}>{item.codigoBarras.substring(6, 9)}</span>
              <span style={{ color: red, fontSize: '24pt', fontWeight: 'bold', marginRight: '7px' }}>{item.codigoBarras.substring(9, 11)}</span>
              <span style={{ color: 'black', fontSize: '24pt', fontWeight: 'bold' }}>{item.codigoBarras.substring(11)}</span>
            </div>
          )}
          <div style={{ marginTop: "-2px" }}>
            <svg ref={(ref) => (barcodeRefs.current[index] = ref)} style={{ width: "110%", height: "auto" }}></svg>
          </div>
          <div style={{ marginTop: "12px", fontSize: '24pt', fontWeight: 'bold' }}>
            IC 2023
          </div>
        </div>
      ))}
    </div>
  );
});

export default BarcodeMasive;
