export var Global = {
    // url: 'http://localhost:8000/api',
    // url: 'http://172.176.145.53/inventory/api',
    url: 'https://sisinvserver.lavoroperu.pe/inventory/api',
    // url_external: 'http://localhost/server_assistance/public/api',
    // url_external: 'http://172.176.145.53/server_assistance/public/api',
    url_external:'https://server.lavoroassistance.pe/api',
    sys_external: 'inventory',
    infoStorage: 'infoInventory',
    path: null,
};
