import React, { useState, useEffect } from 'react'
import UsersService from '../../../services/users';
import ProfileService from '../../../services/profile';
import GeneralService from '../../../services/general';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import BodyUser from '../../components/User/BodyUser';
import AddUser from './AddUser/AddUser';
import InputMain from '../../components/Main/InputMain';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/httpCodes';

const newUser =
{
    "usuario": null,
    "clave": null,
    "apellidos": null,
    "nombres": null,
    "numdoc": null,
    "email": null,
    "persona_id": null,
    "proveedor": null,
    "estado": 1,
    "tipoUsuario": null,
    "perfil": null,
};

const User = () => {
    const url = window.location.pathname;
    const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
    const [openLoad, setOpenLoad] = useState(true);
    const handleOpenLoad = () => setOpenLoad(true);
    const handleCloseLoad = () => setOpenLoad(false);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [state, setState] = useState(null)
    const [typeUser, setTypeUser] = useState(null)

    const [profile, setProfile] = useState(null)
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const pageSize = 20;
    const [totalPages, setTotalPages] = useState(1);
    const [users, setUsers] = useState(null)
    const [user, setUser] = useState(null)

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        Promise.all([
            getUserType(),
            getState(),
            getProfile(),
            getUsers(),
        ]).then(() => { handleCloseLoad(); setTitleLoad(null) });
    }

    const getUserType = async () => {
        try {
            const result = await GeneralService.getUserType(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_tipoUsuario;
                    element.value = element.id_tipoUsuario;
                    element.label = element.destipoUsuario;
                })
                setTypeUser(result.data);
            } else {
                simpleAlerts(result?.response?.data?.message || 'No hay tipos de usuarios', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getState: ${error}`);
        }
    }

    const getState = async () => {
        try {
            const result = await GeneralService.getState(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_estado;
                    element.value = element.id_estado;
                    element.label = element.desEstado;
                })
                setState(result.data);
            } else {
                simpleAlerts('No hay estados', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getState: ${error}`);
        }
    }

    const getProfile = async () => {
        try {
            const result = await ProfileService.getProfile(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_perfil;
                    element.value = element.id_perfil;
                    element.label = element.desPerfil;
                })
                setProfile(result.data);
            } else {
                simpleAlerts('No hay estados', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getState: ${error}`);
        }
    }

    const getUsers = async () => {
        try {
            const result = await UsersService.getUsers(url, { search: search, page: page, pageSize: pageSize });
            if (result.status === CODES.SUCCESS_200) {
                setUsers(result.data[0]);
                setTotalPages(result.data[0].totalPages);
            } else {
                simpleAlerts('No hay usuarios creados');
            }
            handleCloseLoad(); 
            setTitleLoad(null);
            return []
        } catch (error) {
            console.log(`Error en getUsers: ${error}`);
        }
    }

    const viewUser = (info) => {
        handleOpen();
        return setUser(info);
    }

    return (
        <div className='container'>
            <SpinnerLoad open={openLoad} title={titleLoad} />
            <AddUser
                open={open}
                handleClose={handleClose}
                setTitleLoad={setTitleLoad}
                handleOpenLoad={handleOpenLoad}
                handleCloseLoad={handleCloseLoad}
                formatNewUser={newUser}

                state={state}
                profile={profile}
                typeUser={typeUser}
                user={user}
                setUser={setUser}

                getUsers={getUsers}
            />
            <div className="container">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <InputMain
                            title={'Buscar proveedor'}
                            placeholder={'nombre, documento'}
                            width={'50%'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="d-flex justify-content-center align-items-end">
                            <button type="button" className="btn btn-warning mx-4"
                                onClick={() => viewUser(newUser)}
                            >Crear Usuario</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <BodyUser
                        handleOpenLoad={handleOpenLoad}
                        handleCloseLoad={handleCloseLoad}
                        setTitleLoad={setTitleLoad}
                        data={users}
                        search={search}
                        viewUser={viewUser}

                        setPage={setPage}
                        totalPages={totalPages}
                    />
                </div>
            </div>

        </div>
    )
}

export default User