import React, { useState, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import ChargeService from '../../../services/charge';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import BodyConformity from '../../components/Conformity/BodyConformity';
import InputMain from '../../components/Main/InputMain';
import { CODES } from '../../../utils/httpCodes';
import { pathRoute } from '../../../app/config/routesFiles';
import { simpleAlerts } from '../../../utils/alerts';

const Conformity = () => {
  const url = window.location.pathname;
  const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
  const [openLoad, setOpenLoad] = useState(true);
  const handleOpenLoad = () => setOpenLoad(true);
  const handleCloseLoad = () => setOpenLoad(false);
  const [search, setSearch] = useState(null);
  const [charges, setCharges] = useState(null)

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    Promise.all([
      getChargeByUser()
    ]).then(() => { handleCloseLoad(); setTitleLoad(null) });
  }

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 9;
  useEffect(() => {
    getChargeByUser();
  }, [page]);
  useEffect(() => {
    setCharges(null);
    setTotalPages(1);
  }, [search]);
  const getChargeByUser = async () => {
    try {
      handleOpenLoad();
      setTitleLoad("Buscando cargos ...");
      const params = {
        page: page,
        pageSize: pageSize,
        search: search,
      };
      const result = await ChargeService.getChargeByUser(url, params);
      if (result.status === CODES.SUCCESS_200) {
        let dataTmp = result.data[0];
        dataTmp?.forEach(element => {
          element.ruta = (element.ruta ? pathRoute.path + element.ruta : element.ruta);
          element.conformidad = (element.conformidad ? pathRoute.path + element.conformidad : element.conformidad);
          element.conformidadName = null;
          element.conformidadFile = null;

          element.devolucion = (element.devolucion ? pathRoute.path + element.devolucion : element.devolucion);
          element.devolucionName = null;
          element.devolucionFile = null;
        })
        setCharges(dataTmp);
        setTotalPages(result.data[1]?.totalPages);
      } else {
        simpleAlerts(result?.response?.data?.message || 'No se encontraron centros de costos', 'info');
      }
      return []
    } catch (error) {
      console.log(`Error en getCostCenter: ${error}`);
    } finally {
      handleCloseLoad();
      setTitleLoad(null);
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      getChargeByUser();
    }
  };

  return (
    <div className='container'>
      <SpinnerLoad open={openLoad} title={titleLoad} />

      <div className="container">
        <div className="row">
          <div className="col d-flex justify-content-center align-items-end">
            <InputMain
              title={'Cargo'}
              placeholder={'Buscar equipo'}
              width={'90%'}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              type="button"
              className="btn btn-outline-warning btn-group-custom p-2"
              onClick={getChargeByUser}>
              <SearchIcon fontSize='small' />
            </button>
          </div>
        </div>
      </div>
      <BodyConformity
        handleOpenLoad={handleOpenLoad}
        handleCloseLoad={handleCloseLoad}
        setTitleLoad={setTitleLoad}
        search={search}
        getChargeByUser={getChargeByUser}
        data={charges}
        setData={setCharges}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
      />
    </div >
  )
}

export default Conformity