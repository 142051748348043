import React from 'react';
import InputMain from '../../Main/InputMain';
import SelectMain from '../../Main/SelectMain';
import _ from 'lodash';

const newItem = {
    "detalle": 'detalle 1',
    "valor": 'valor 1',
    "editable": 0,
}

const InfoGeneral = ({ companies, dataCategory, providers, equipmentState, equipmentType, equipment, setEquipment, detailDelete, setDetailDelete }) => {
    const validationCol = (equipment?.detalleEquipo && equipment?.detalleEquipo?.length > 0);

    const addDetail = () => {
        let updEquipment = _.cloneDeep(equipment);
        updEquipment.detalleEquipo.push(newItem);
        setEquipment(updEquipment);
    }

    const removeDetail = (info, index) => {
        let updEquipment = _.cloneDeep(equipment);
        let updDetailDelete = _.cloneDeep(detailDelete);

        if (info?.id_detalle) {
            updDetailDelete.push(info);
        }

        updEquipment.detalleEquipo = updEquipment?.detalleEquipo?.filter((e, idx) => idx !== index);

        setEquipment(updEquipment);
        setDetailDelete(updDetailDelete);
    }

    const handleChangDetail = (value, key, idx) => {
        let updEquipment = _.cloneDeep(equipment);
        updEquipment.detalleEquipo[idx][key] = value;
        setEquipment(updEquipment);
    }    

    return (
        <div className="container" style={{}}>
            <div className="row mt-2">
                <div className="col-md-5 col-sm-12">
                    <InputMain
                        title={'Nombre'}
                        titleColor={'black'}
                        placeholder={'Nombre del equipo'}
                        value={equipment?.nombre || ''}
                        name={'nombre'}
                        onChange={(e) => setEquipment({ ...equipment, nombre: e.target.value })}
                    />
                </div>
                <div className="col-md-7 col-sm-12">
                    <div className="form-floating">
                        <textarea className="form-control" id="floatingTextarea"
                            value={equipment?.caracteristicas || ''}
                            name={'caracteristicas'}
                            onChange={(e) => setEquipment({ ...equipment, caracteristicas: e.target.value })}
                        ></textarea>
                        <label htmlFor="floatingTextarea">Descripción (Opcional)</label>
                    </div>
                </div>
            </div>
            <div className="row border-top mt-2">
                <div className={`col-md-${validationCol ? '7' : '12'} col-sm-12`}>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <SelectMain
                                title={'Empresa'}
                                titleColor={'black'}
                                placeholder={'Seleccionar empresa'}
                                width={'100%'}
                                options={companies}
                                value={equipment?.empresa_id || ''}
                                name={'empresa'}
                                onChange={(value, event) => setEquipment({ ...equipment, empresa_id: value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <SelectMain
                                title={'Proveedor'}
                                titleColor={'black'}
                                placeholder={'Seleccionar proveedor'}
                                width={'100%'}
                                options={providers}
                                value={equipment?.proveedor?.id_proveedor || equipment?.proveedor || 0}
                                name={'proveedor'}
                                onChange={(value, event) => setEquipment({ ...equipment, proveedor: value })}
                            />
                        </div>
                        <div className='col'>
                            <SelectMain
                                title={'Tipo equipo'}
                                titleColor={'black'}
                                placeholder={'Seleccionar tipo'}
                                width={'100%'}
                                options={equipmentType}
                                value={equipment?.tipoEquipo?.id_tipoEquipo || equipment?.tipoEquipo || ''}
                                name={'tipoEquipo'}
                                onChange={(value, event) => setEquipment({ ...equipment, tipoEquipo: value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InputMain
                                title={'Fecha adquisición'}
                                titleColor={'black'}
                                type={'date'}
                                value={equipment?.fechaAdq || ''}
                                name={'fechaAdq'}
                                onChange={(e) => setEquipment({ ...equipment, fechaAdq: e.target.value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InputMain
                                title={'Valor'}
                                titleColor={'black'}
                                placeholder={'Valor del equipo'}
                                value={equipment?.valor || ''}
                                name={'valor'}
                                onChange={(e) => setEquipment({ ...equipment, valor: e.target.value })}
                                propsInput={{
                                    min: 0,
                                    type: 'number',
                                }}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <SelectMain
                                title={'Estado del equipo'}
                                titleColor={'black'}
                                placeholder={'Seleccionar estado'}
                                width={'100%'}
                                options={equipmentState}
                                value={equipment?.estadoEqp?.id_estadoEqp || equipment?.estadoEqp || ''}
                                name={'estadoEqp'}
                                onChange={(value, event) => setEquipment({ ...equipment, estadoEqp: value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InputMain
                                title={'Marca'}
                                titleColor={'black'}
                                placeholder={'Marca del equipo'}
                                value={equipment?.marca || ''}
                                name={'marca'}
                                onChange={(e) => setEquipment({ ...equipment, marca: e.target.value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InputMain
                                title={'Modelo'}
                                titleColor={'black'}
                                placeholder={'Modelo del equipo'}
                                value={equipment?.modelo || ''}
                                name={'modelo'}
                                onChange={(e) => setEquipment({ ...equipment, modelo: e.target.value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <SelectMain
                                title={'Categoria'}
                                titleColor={'black'}
                                placeholder={'Seleccionar categoria'}
                                width={'100%'}
                                options={dataCategory}
                                value={equipment?.categoria?.id_categoria || equipment?.categoria || ''}
                                name={'categoria'}
                                onChange={(value, event) => setEquipment({ ...equipment, categoria: value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InputMain
                                title={'Serial'}
                                titleColor={'black'}
                                placeholder={'Nro de serie'}
                                value={equipment?.nroSerie || ''}
                                name={'nroSerie'}
                                onChange={(e) => setEquipment({ ...equipment, nroSerie: e.target.value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InputMain
                                title={'IMEI'}
                                titleColor={'black'}
                                placeholder={'IMEI del equipo'}
                                value={equipment?.imei || ''}
                                name={'imei'}
                                onChange={(e) => setEquipment({ ...equipment, imei: e.target.value })}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <button type="button" className="btn btn-sm btn-outline-success mt-4" onClick={addDetail}>
                                <i className="fa-solid fa-file-circle-plus"></i>&nbsp;Agregar detalle
                            </button>
                        </div>
                    </div>
                </div>
                {validationCol &&
                    <div className="col-md-5 col-sm-12 mt-4 animate__animated animate__bounceInRight" style={{ border: 'solid 1px', borderRadius: '25px', maxHeight: '50vh', overflow: 'scroll' }}>
                        {equipment?.detalleEquipo?.map((detalle, idx) => {
                            return (
                                <div className='row mt-2 mb-2' key={`detalle${idx}`}>
                                    <div className='col'>
                                        <div className="input-group input-group-sm">
                                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                value={detalle.detalle}
                                                disabled={detalle.editable === 1 ? true : false}
                                                onChange={(e) => handleChangDetail(e.target.value, 'detalle', idx)}
                                            />
                                            <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)"
                                                value={detalle.valor}
                                                disabled={detalle.editable === 1 ? true : false}
                                                onChange={(e) => handleChangDetail(e.target.value, 'valor', idx)}
                                            />
                                            {detalle.editable === 1 ?
                                                <>
                                                    <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={() => handleChangDetail(0, 'editable', idx)}><i className="fa-solid fa-pen-to-square text-warning"></i></span>
                                                    <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={() => removeDetail(detalle, idx)}><i className="fa-solid fa-trash text-danger"></i></span>
                                                </>
                                                : <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={() => handleChangDetail(1, 'editable', idx)}><i className="fa-solid fa-floppy-disk text-success"></i></span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        </div >
    )
}

export default InfoGeneral