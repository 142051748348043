import React, { } from 'react'
import ModalMain from '../../components/Main/ModalMain';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const SeeDocuments = (props) => {
    const { open, handleClose, urlDocument, } = props;
    const handleDownloadClick = () => {
        // Crear un hipervínculo oculto
        const link = document.createElement('a');
        link.href = urlDocument;
        link.download = 'conformidad.jpg';
        link.target = '_blank';
        link.click();
    };

    return (
        <ModalMain
            size={'lg'}
            open={open}
            handleClose={handleClose}
            title={`Busqueda de documento`}
            subTitle={`Descarga de documentos`}
            body={
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div id="pdfContainer" style={{ flex: 1 }}>
                        <iframe src={urlDocument} width="100%" height="500px"></iframe>
                    </div>
                    &nbsp;&nbsp;
                    <IconButton
                        aria-label="Descargar"
                        size="large"
                        onClick={handleDownloadClick}
                        style={{
                            backgroundColor: '#007bff', // Color de fondo
                            color: '#fff', // Color del ícono
                            padding: '12px', // Espaciado interno
                            borderRadius: '50%', // Forma redonda
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Sombra
                            transition: 'background-color 0.3s ease', // Animación al pasar el mouse
                        }}
                    >
                        <CloudDownloadIcon style={{ fontSize: '24px' }} /> {/* Tamaño del ícono */}
                    </IconButton>
                </div>
            }
            buttonActions={[]}
        />
    );
}

export default SeeDocuments;
