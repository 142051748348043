import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class WorkerExternalService {
    getWorkerExternalAll(url) {
        let ruta = `${API_URL}/worker-external?url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getWorkerExternal(url, documento) {
        let ruta = `${API_URL}/worker-external/${documento}?url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveWorkerExternal(url, body) {
        let ruta = `${API_URL}/worker-external?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new WorkerExternalService();
