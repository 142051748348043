import React from 'react';
import InfoMain from '../../Main/InfoMain';
import InputMain from '../../Main/InputMain';
import SelectMain from '../../Main/SelectMain';

const InfoGeneral = ({ cecos, infoBuy, setInfoBuy }) => {

    return (
        <div className="container" style={{ maxHeight: '40vh', overflow: 'scroll' }}>
            <div className="row">
                <div className='col-sm-8'>
                    <InfoMain
                        title={'Trabajador'}
                        titleColor={'black'}
                        placeholder={'Nombre del trabajador'}
                        value={infoBuy?.trabajador || ''}
                    />
                </div>
                <div className='col-sm-4'>
                    <InfoMain
                        title={'Documento'}
                        titleColor={'black'}
                        placeholder={'Nro. Documento'}
                        value={infoBuy?.numdoc || ''}
                    />
                </div>
                <div className='col-sm-6'>
                    <InfoMain
                        title={'Empresa'}
                        titleColor={'black'}
                        placeholder={'Nombre de la empresa'}
                        value={infoBuy?.desEmpresa || ''}
                    />
                </div>
                <div className='col-sm-6'>
                    <InfoMain
                        title={'Puesto'}
                        titleColor={'black'}
                        placeholder={'Puesto del trabajador'}
                        value={infoBuy?.desPuesto || ''}
                    />
                </div>
                <div className='col-sm-6'>
                    <InfoMain
                        title={'Dirección'}
                        titleColor={'black'}
                        placeholder={'Dirección'}
                        value={infoBuy?.direccion || ''}
                    />
                </div>
                <div className='col-sm-6'>
                    <InfoMain
                        title={'Equipo'}
                        titleColor={'black'}
                        placeholder={'Nombre del equipo'}
                        value={infoBuy?.nombreEquipo || ''}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <SelectMain
                        title={'Centro de costos'}
                        titleColor={'black'}
                        placeholder={'Seleccionar CECOS'}
                        options={cecos}
                        value={infoBuy?.centroCosto || ''}
                        onChange={(value, event) => setInfoBuy({ ...infoBuy, centroCosto: value })}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Precio de remate'}
                        titleColor={'black'}
                        placeholder={'Precio de remate'}
                        type={'number'}
                        value={infoBuy?.valorRemate || ''}
                        onChange={(e) => setInfoBuy({ ...infoBuy, valorRemate: e.target.value })}
                        required={true}
                        propsInput={{
                            min: 0,
                            step: 0.01,
                            type: 'number',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoGeneral