import React, { useState, useEffect } from 'react';
import ExternalService from '../../../services/external';
import GeneralService from '../../../services/general';
import BuyService from '../../../services/buy';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import BodyBuyEquipment from '../../components/Buy/BodyBuyEquipment';
import InputMain from '../../components/Main/InputMain';
import AddInformationBuy from './AddInformationBuy/AddInformationBuy';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';

const BuyEquipment = () => {
    const url = window.location.pathname;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
    const [openLoad, setOpenLoad] = useState(true);
    const handleOpenLoad = () => setOpenLoad(true);
    const handleCloseLoad = () => setOpenLoad(false);

    const [search, setSearch] = useState(''); //70565330
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [workers, setWorkers] = useState(null);
    const [worker, setWorker] = useState(null);
    const [equipment, setEquipment] = useState(null);

    const [cecos, setCecos] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        Promise.all([
            getCostCenter(),
            getWorker(),
        ]).then(() => { handleCloseLoad(); setTitleLoad(null) });
    }

    const getCostCenter = async () => {
        try {
            const result = await GeneralService.getCostCenter();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_centroCosto
                    element.label = element.names
                    element.value = element.id_centroCosto
                })
                setCecos(result?.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getWorker: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null)
        }
    }

    const getWorker = async () => {
        handleOpenLoad();
        setTitleLoad("Buscando trabajadores ...")
        try {
            const result = await ExternalService.getWorker(url, { search: search || '', page: page });
            if (result.status === CODES.SUCCESS_200) {
                result?.data?.data.forEach(element => {
                    element.viewEquipment = false
                })
                setWorkers(result?.data?.data);
                setTotalPages(result?.data?.last_page);
            }
            return []
        } catch (error) {
            console.log(`Error en getWorker: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null)
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getWorker();
        }
    };

    const buyEquipment = (infoWorker, infoEquipment) => {
        setWorker(infoWorker);
        setEquipment(infoEquipment);
        handleOpen();
    }

    const [infoEquipments, setInfoEquipments] = useState(null);
    const listEquipment = async (row) => {
        const newData = workers.map((element) => { //data.map((element) => {
            // Si el id del elemento coincide con el id del item, actualiza el nombre
            if (element.id_persona === row.id_persona) {
                // return { ...element, viewEquipment: row.viewEquipment };
                return { ...element, viewEquipment: true };
            }
            // Si no coincide, simplemente devuelve el elemento sin cambios
            return element;
        });
        handleOpenLoad();
        setTitleLoad('Buscando ...');
        try {
            setInfoEquipments(null);
            const result = await BuyService.getInfoWorkerEquipment(url, row?.id_persona);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts("No tiene equipos asignados");
                    return;
                }
                setInfoEquipments(result?.data)
                setWorkers(newData);//setData(newData);
            }
            return [];
        } catch (error) {
            console.log(`Error en deleteCharge: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    return (
        <div>
            <AddInformationBuy
                open={open}
                handleClose={handleClose}
                setTitleLoad={setTitleLoad}
                handleOpenLoad={handleOpenLoad}
                handleCloseLoad={handleCloseLoad}
                cecos={cecos}
                worker={worker}
                setWorker={setWorker}
                equipment={equipment}
                setEquipment={setEquipment}
                listEquipment={listEquipment}
            />
            <div className='container' style={{ marginTop: '0.5rem' }}>
                <div className='animate__animated animate__backInLeft'>
                    <div className='d-flex justify-content-center'>
                        <InputMain
                            title={'Buscar trabajadores'}
                            placeholder={'nombre del trabajador, documento'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            width={'95%'}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <BodyBuyEquipment
                        setTitleLoad={setTitleLoad}
                        handleOpenLoad={handleOpenLoad}
                        handleCloseLoad={handleCloseLoad}
                        data={workers}
                        setData={setWorkers}
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        buyEquipment={buyEquipment}
                        infoEquipments={infoEquipments}
                        listEquipment={listEquipment}
                    />
                </div>
            </div>
            <SpinnerLoad open={openLoad} title={titleLoad} />
        </div >
    );
};

export default BuyEquipment