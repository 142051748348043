import React, { } from 'react';
import { filterData } from '../../../utils/function';

const columns = [
    { id: 'id_externo', label: '#', width: 20, align: 'center', },
    { id: 'documento', label: 'Documento', width: 50, align: 'center', },
    { id: 'nombres', label: 'Nombres', width: 120, align: 'left', },
    { id: 'apellidos', label: 'Apellidos', width: 120, align: 'left', },
    { id: 'actions', label: 'Acciones', width: 50, align: 'center', },
];

const columnsMap = {
    estado_id: 'estado.desEstado',
};

function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodyWorkerExternal = ({ search, editWorker, data, }) => {
    let resultSearch = filterData(data, search, ['documento', 'nombres', 'apellidos']);

    return (
        <div className='body-table table-responsive'>
            <div className="container">
                <div className="body-table-wrapper mt-3">
                    <table className="table table-bordered table-hover table-sm">
                        <thead className="table-dark sticky-top">
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        scope="col"
                                        key={col.id}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`,
                                        }}
                                    >
                                        {col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {resultSearch?.map((row, idx) => {
                                return (
                                    <tr key={idx}>
                                        {columns.map((col) => {
                                            const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                            return (
                                                <td
                                                    key={col.id}
                                                    style={{
                                                        textAlign: col.align,
                                                        width: `${col.width}px`,
                                                        maxWidth: `${col.width}px`,
                                                    }}
                                                >
                                                    {col.id === 'actions' ?
                                                        <button type="button" className="btn btn-outline-dark btn-sm mr-1 buttons-grid"
                                                            onClick={() => editWorker(row)}
                                                        >
                                                            <i className="fa-solid fa-pen-to-square"></i>
                                                        </button>
                                                        : (col.type === 'number' && col.format) ?
                                                            col.format(value)
                                                            : value
                                                    }

                                                </td>

                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BodyWorkerExternal