import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import { primary, tertiary, quaternary, } from '../../../assets/colors.js';
import SpinnerLoad from '../Main/SpinnerLoad/SpinnerLoad.js';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Global } from '../../../utils/general.js';
const titleSystem = 'Inventory LP';

const BarHeader = ({ setUserToken, pathInit, menu }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [titleLoad, setTitleLoad] = useState(null);
    const [openLoad, setOpenLoad] = useState(false);
    const handleOpenLoad = () => setOpenLoad(true);
    const handleCloseLoad = () => setOpenLoad(false);
    const [isTransparent, setIsTransparent] = useState(true);
    const [isSmall, setIsSmall] = useState(false);

    // Función para controlar el cambio en el scroll
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Define la altura a la que quieres que el header sea transparente
        const scrollThreshold = 300;
        // Cambia el estado para indicar si es transparente o no
        setIsTransparent(scrollPosition > scrollThreshold);
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 992px)"); // Media Query para tamaños de pantalla menores o iguales a 768px (tamaño de una tablet)
        const handleMediaChange = (event) => {
            setIsSmall(event.matches);
        };
        mediaQuery.addEventListener("change", handleMediaChange);
        handleMediaChange(mediaQuery);


        // Agrega el evento de scroll al montar el componente
        window.addEventListener('scroll', handleScroll);
        // Llama manualmente a handleScroll para que el estado se actualice en la carga inicial
        handleScroll();
        // Elimina el evento de scroll al desmontar el componente
        return () => {
            window.removeEventListener('scroll', handleScroll);
            mediaQuery.removeEventListener("change", handleMediaChange);
        };
    }, []);

    const signOut = () => {
        setTitleLoad('Cerrando ....');
        handleOpenLoad();
        setTimeout(() => {
            localStorage.removeItem(Global.infoStorage);
            setUserToken(null);
            setTitleLoad(null);
            handleCloseLoad();
            navigate(pathInit);
            window.location.reload();
        }, 3000);
    }

    return (
        <div>
            <SpinnerLoad open={openLoad} title={titleLoad} />
            {isSmall ?
                <MenuSmall pathInit={pathInit} location={location} isTransparent={isTransparent} signOut={signOut} menu={menu} /> :
                <Menularge pathInit={pathInit} location={location} isTransparent={isTransparent} signOut={signOut} menu={menu} />
            }
        </div>
    )
}

const Menularge = ({ pathInit, location, isTransparent, signOut, menu }) => {
    return (
        <div style={{ marginBottom: 60 }}>
            <nav className={`fixed-top navbar navbar-expand-lg ${isTransparent ? 'navbar-transparent animate__animated animate__flash' : 'bg-body-tertiary'} ${isTransparent ? "navbar-white" : ""}`}
                style={{ border: `solid 1px ${tertiary}`, fontSize: "1rem", padding: "0.3rem 1rem", margin: "0.8rem 0.8rem 0rem 0.8rem", borderRadius: "15px" }}
            >
                <div className="container-fluid">
                    <a className="navbar-brand" href="#" style={{ fontSize: "1.1rem" }}>{titleSystem}</a>
                    <button className="navbar-toggler btn-sm" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarScroll">
                        <ul className={`${isTransparent ? 'navbar-light' : ''} navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll`}>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="#"></a>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === pathInit ? 'active' : ''}`} to={pathInit}>
                                    Home
                                </Link>
                            </li>
                            {menu?.map((item) => {
                                return (
                                    <li className="nav-item" key={item.key}>
                                        <Link className={`nav-link ${location.pathname === item.key ? 'active' : ''}`} to={item.key}>
                                            {item.label}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                        <form className="d-flex" role="search">
                            <button className="btn btn-outline-danger btn-sm" type="button" title='Cerrar sesión'
                                style={{ fontSize: "0.8rem" }}
                                onClick={signOut}
                            >
                                <PowerSettingsNewIcon />
                            </button>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    );
}

const MenuSmall = ({ pathInit, location, isTransparent, signOut, menu }) => {
    return (
        <div style={{ marginBottom: 60 }}>
            <nav className={`navbar fixed-top ${isTransparent ? 'navbar-transparent animate__animated animate__flash' : 'bg-body-tertiary'} ${isTransparent ? "navbar-white" : ""}`}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">{titleSystem}</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">{titleSystem}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <Link className={`nav-link ${isTransparent ? 'dark' : ''} ${location.pathname === pathInit ? 'small active' : ''}`} to={pathInit}>
                                        Home
                                    </Link>
                                </li>
                                {menu?.map((item) => {
                                    return (
                                        <li className="nav-item" key={item.key}>
                                            <Link className={`nav-link ${isTransparent ? 'dark' : ''} ${location.pathname === item.key ? 'small active' : ''}`} to={item.key}>
                                                {item.label}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                            <form className="d-flex justify-content-end mt-3" role="search">
                                <button className="btn btn-outline-danger" type="button" onClick={signOut}><PowerSettingsNewIcon /></button>
                            </form>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default BarHeader