import React, { useState, useEffect } from 'react'
import WorkerExternalService from '../../../services/workerExternal';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import AddWorkerExternal from './AddWorkerExternal/AddWorkerExternal';
import InputMain from '../../components/Main/InputMain';
import BodyWorkerExternal from '../../components/WorkerExternal/BodyWorkerExternal';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';

const newItem = {
    "documento": null,
    "nombres": null,
    "apellidos": null,
    "direccion": null,
    "email": null,
    "telefono": null,
}

const WorkerExternal = () => {
    const url = window.location.pathname;
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
    const [openLoad, setOpenLoad] = useState(false);
    const handleOpenLoad = () => setOpenLoad(true);
    const handleCloseLoad = () => setOpenLoad(false);

    const [search, setSearch] = useState("");
    const [workers, setWorkers] = useState(null);
    const [worker, setWorker] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        Promise.all([
            getWorkerExternalAll(),
        ]).then(() => { handleCloseLoad(); setTitleLoad(null) });
    }

    const getWorkerExternalAll = async () => {
        try {
            handleOpenLoad();
            setTitleLoad('Listando trabajadores ...');
            const result = await WorkerExternalService.getWorkerExternalAll(url);
            if (result.status === CODES.SUCCESS_200) {
                setWorkers(result.data);
            } else {
                simpleAlerts('No se encontro trabajadores', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getWorkerExternalAll: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const editWorker = (info) => {
        setWorker(info);
        handleOpen();
    }

    return (
        <div className='container'>
            <AddWorkerExternal
                open={open}
                handleClose={handleClose}
                setTitleLoad={setTitleLoad}
                handleOpenLoad={handleOpenLoad}
                handleCloseLoad={handleCloseLoad}

                worker={worker}
                setWorker={setWorker}
                getWorkerExternalAll={getWorkerExternalAll}
            />
            <SpinnerLoad open={openLoad} title={titleLoad} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 d-flex justify-content-center">
                        <InputMain
                            title={'Buscar trabajador'}
                            placeholder={'documento, nombres, apellidos'}
                            width={'50%'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="d-flex justify-content-center align-items-end">
                            <button type="button" className="btn btn-outline-warning mx-4"
                                onClick={() => editWorker(newItem)}
                                title='Crear Trabajador'
                            ><i className="fa-solid fa-circle-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <BodyWorkerExternal
                        search={search}
                        editWorker={editWorker}
                        data={workers}
                    />
                </div>
            </div>
        </div>
    )
}

export default WorkerExternal;