import axios from "axios";
import { Global } from '../utils/general';

const API_URL = Global.url;
const PREFIX = 'auth';
class AuthService {
    signIn(body) {
        let ruta = `${API_URL}/${PREFIX}/sign-in`;
        return axios
            .post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }

    signOut() {
        let ruta = `${API_URL}/logout`;
        return axios
            .post(ruta)
            .catch((error) => {
                return error.response;
            });
    }
}

export default new AuthService();