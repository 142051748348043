import { React, useEffect } from 'react';
import EquipmentService from '../../../services/equipment';
import PaginationMain from '../Main/PaginationMain';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';
import PrintBarCode from '../../../app/components/Equipment/PrintBarcode';

const columns = [
    { id: 'id_equipo', label: '#', width: 30, align: 'center', },
    { id: 'nombre', label: 'Nombre', width: 90, align: 'left', },
    { id: 'marca', label: 'Marca', width: 90, align: 'center', },
    { id: 'modelo', label: 'Modelo', width: 100, align: 'center', },
    { id: 'nroSerie', label: 'Serie', width: 100, align: 'center', },
    { id: 'imei', label: 'IMEI', width: 100, align: 'center', },
    { id: 'valor', label: 'Valor', width: 100, align: 'center', type: 'number', format: (value) => parseFloat(value)?.toFixed(2), },
    { id: 'codigoBarras', label: 'CB', width: 95, align: 'center', },
    { id: 'estadoEqp_id', label: 'Estado Eqp', width: 100, align: 'center', },
    { id: 'categoria_id', label: 'Categoria', width: 100, align: 'center', },
    { id: 'actions', label: 'Acciones', width: 60, align: 'center', },
    { id: 'sel', label: 'CB.', width: 50, align: 'center', },
];
const columnsMap = {
    estado_id: 'estado.desEstado',
    estadoEqp_id: 'estadoEqp.descripcion',
    proveedor_id: 'proveedor.nombre',
    categoria_id: 'categoria.nombre',
};
function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodyEquipment = ({ handleOpenLoad, handleCloseLoad, setTitleLoad, getEquipment, data, viewDetailEquipment, resultSearch, selectedItems, setSelectedItems, selectAll, setSelectAll,
    totalPages, page, setPage, handleOpen, setInformationAll,
}) => {
    const url = window.location.pathname;

    useEffect(() => {
        // Manejar la selección de todos cuando cambia el estado de "selectAll"
        if (selectAll) {
            const allItems = resultSearch.map((row) => ({
                id_equipo: row.id_equipo,
                codigoBarras: row.codigoBarras[0].codigoBarras,
                rucEmpresa: row.rucEmpresa,
                // Agrega más campos aquí según tu estructura
            }));
            setSelectedItems(allItems);
        } else {
            setSelectedItems([]);
        }
    }, [selectAll, resultSearch]);

    const handleHeaderCheckboxChange = () => {
        setSelectAll(!selectAll);
        const allItems = resultSearch.map((row) => ({
            id_equipo: row.id_equipo,
            codigoBarras: row?.codigoBarras[0]?.codigoBarras,
            rucEmpresa: row.rucEmpresa,
            // Agrega más campos aquí según tu estructura
        }));

        if (!selectAll) {
            // Si selectAll es false, significa que estamos activando la selección de todos
            setSelectedItems(allItems);
        } else {
            // Si selectAll es true, significa que estamos desactivando la selección de todos
            setSelectedItems([]);
        }
    };

    const handleCheckboxChange = (row) => {
        const updatedSelectedItems = [...selectedItems];

        const itemIndex = updatedSelectedItems.findIndex(
            (item) => item.id_equipo === row.id_equipo
        );

        if (itemIndex === -1) {
            // Si no está seleccionado, agregarlo
            updatedSelectedItems.push({
                id_equipo: row.id_equipo,
                codigoBarras: row.codigoBarras[0].codigoBarras,
                rucEmpresa: row.rucEmpresa,
                // Agrega más campos aquí según tu estructura
            });
        } else {
            // Si ya está seleccionado, quitarlo
            updatedSelectedItems.splice(itemIndex, 1);
        }

        setSelectedItems(updatedSelectedItems);
    };

    const deleteCategory = async (info) => {
        try {
            handleOpenLoad();
            setTitleLoad('Eliminando ...');
            const result = await EquipmentService.deleteEquipment(url, info.id_equipo);
            if (result.status === CODES.DELETE_204) {
                simpleAlerts(result?.data?.message || 'Eliminado', 'success');
                getEquipment();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                handleCloseLoad();
                setTitleLoad(null);
            }
            return [];
        } catch (error) {
            console.log(`Error en deleteCategory: ${error}`);
        }
    }

    const getEquipmentBydIdInfo = async (info) => {
        try {
            handleOpenLoad();
            setTitleLoad('Buscando información ...');
            const result = await EquipmentService.getEquipmentBydIdInfo(url, info.id_equipo);
            if (result.status === CODES.SUCCESS_200) {
                setInformationAll(result.data);
                handleOpen();
            }
            return [];
        } catch (error) {
            console.log(`Error en deleteCategory: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    return (
        <div className='body-table table-responsive'>
            <div className="container">
                <div className="body-table-wrapper mt-3">
                    <table className="table table-bordered table-hover table-sm">
                        <thead className="table-dark sticky-top" style={{ fontSize: '10px' }}>
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        scope="col"
                                        key={col.id}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`
                                        }}
                                    >
                                        {col.id === 'sel' ?
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label htmlFor={`checkbox-rect-header`} className="ml-1">CB.</label>
                                                <input
                                                    style={{ marginLeft: '5px' }}
                                                    type="checkbox"
                                                    name="check"
                                                    id={`checkbox-rect-header`}
                                                    className={'checkbox-rect-header'}
                                                    checked={selectAll}
                                                    onChange={handleHeaderCheckboxChange}
                                                />
                                            </div>
                                            : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ marginRight: 'auto' }}>{col.label}</div>

                                            </div>}

                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '10px' }}>
                            {data?.map((row, idx) => {
                                return (
                                    <tr key={idx}>
                                        {columns.map((col) => {
                                            const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                            return (
                                                <td
                                                    key={col.id}
                                                    style={{
                                                        textAlign: col.align,
                                                        width: `${col.width}px`,
                                                        maxWidth: `${col.width}px`
                                                    }}
                                                >
                                                    {col.id === 'codigoBarras' ?
                                                        row?.codigoBarras[0]?.codigoBarras
                                                        : col.id === 'actions' ?
                                                            <div className='d-flex'>
                                                                <button type="button" className="btn btn-outline-warning btn-sm mr-1 buttons-grid"
                                                                    onClick={() => getEquipmentBydIdInfo(row)}
                                                                    title='Resumen de equipo'
                                                                >
                                                                    <i className="fa-solid fa-rectangle-list"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-outline-dark btn-sm mr-1 buttons-grid"
                                                                    onClick={() => viewDetailEquipment(row)}
                                                                    title='Editar equipo'
                                                                >
                                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-outline-danger btn-sm mr-1 buttons-grid"
                                                                    onClick={() => deleteCategory(row)}
                                                                    title='Eliminar equipo'
                                                                >
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </button>
                                                            </div>
                                                            : (col.type === 'number' && col.format) ?
                                                                col.format(value)
                                                                : col.id === 'sel' ?
                                                                    <div className="checkbox-custom"
                                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedItems.some(item => item.id_equipo === row.id_equipo)}
                                                                            onChange={() => handleCheckboxChange(row)}

                                                                        />
                                                                        <label htmlFor={`checkbox-rect`}></label>
                                                                    </div>
                                                                    : value}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <PaginationMain
                size={'small'}
                totalPages={totalPages}
                page={page}
                setPage={setPage}
            />
        </div>
    )
}

export default BodyEquipment