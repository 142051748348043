import React, { useEffect, useState } from 'react';
import PaginationMain from '../Main/PaginationMain';
import SeeDocuments from '../../pages/Charge/SeeDocuments';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PageviewIcon from '@mui/icons-material/Pageview';
import PreviewIcon from '@mui/icons-material/Preview';
import ChargeService from '../../../services/charge';
import { filterData, getDateOL, timeStampString, validateUser } from '../../../utils/function';
import { allowedTypesConformity, maxFileSizeConformityMB } from '../../config/allowedTypes';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/httpCodes';
import _ from 'lodash';

const columnsGeneral = [
    { id: 'id_cargo', label: '#', width: 50, align: 'center', },
    { id: 'trabajador', label: 'Nombre', width: 100, align: 'center', },
    { id: 'fechaEntrega', label: 'Entrega', width: 40, align: 'center', },
    { id: 'equipo', label: 'Equipo', width: 80, align: 'center', },
    { id: 'nroSerie', label: 'N° Serie', width: 50, align: 'center', },
    { id: 'valor', label: 'Valor', width: 50, align: 'center', type: 'number', format: (value) => parseFloat(value)?.toFixed(2), },
    // { id: 'estadoEqp', label: 'Estado', width: 50, align: 'center', },
    { id: 'conformidad', label: 'Confor.', width: 30, align: 'center', },
    { id: 'ruta', label: 'Cargo', width: 20, align: 'center', },
];
const columnsAdmin = [
    { id: 'id_cargo', label: '#', width: 50, align: 'center', },
    { id: 'trabajador', label: 'Nombre', width: 100, align: 'center', },
    { id: 'fechaEntrega', label: 'Entrega', width: 40, align: 'center', },
    { id: 'equipo', label: 'Equipo', width: 80, align: 'center', },
    { id: 'nroSerie', label: 'N° Serie', width: 50, align: 'center', },
    { id: 'valor', label: 'Valor', width: 50, align: 'center', type: 'number', format: (value) => parseFloat(value)?.toFixed(2), },
    // { id: 'estadoEqp', label: 'Estado', width: 50, align: 'center', },
    { id: 'conformidad', label: 'Confor.', width: 30, align: 'center', },
    { id: 'devolucion', label: 'Devolu.', width: 30, align: 'center', },
    { id: 'ruta', label: 'Cargo', width: 20, align: 'center', },
];
const columnsMap = {
    tipoEquipo: 'equipo.tipoEquipo.desTipoEquipo',
    equipo: 'equipo.nombre',
    nroSerie: 'equipo.nroSerie',
    valor: 'equipo.valor',
    categoria: 'equipo.categoria.nombre',
    estadoEqp: 'equipo.estadoEqp.descripcion',
    proveedor: 'equipo.proveedor.nombre',
};
function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodyConformity = ({ handleOpenLoad, handleCloseLoad, setTitleLoad, getChargeByUser, data, setData, search, totalPages, page, setPage, }) => {
    const dataUser = JSON.parse(validateUser());
    const url = window.location.pathname;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [columns, setColumns] = useState(null);
    useEffect(() => {
        if (dataUser?.user?.perfil?.desPerfil === 'ADMIN') {
            setColumns(columnsAdmin)
        } else {
            setColumns(columnsGeneral)
        }
    }, [dataUser])

    // let resultSearch = filterData(data, search, ['equipo.nroSerie', 'equipo.estadoEqp.descripcion', 'equipo.nombre', 'numdoc']);
    const [urlDocument, setUrlDocument] = useState(null);

    const getFileType = (file) => {
        return Object.keys(allowedTypesConformity).find(type => allowedTypesConformity[type].includes(file.type));
    };

    const isFileTypeAllowed = (fileType) => {
        return allowedTypesConformity[fileType] ? true : false;
    };

    const isFileSizeValid = (file) => {
        const fileType = getFileType(file);
        if (!fileType) {
            return false;
        }
        const maxSizeMB = maxFileSizeConformityMB[fileType];
        return file.size / (1024 * 1024) <= maxSizeMB;
    };

    const handleFileChange = (event, infoCargo, type, { keyName, keyFile }) => {
        let updData = _.cloneDeep(data);
        const idx = updData.findIndex(e => +e.id_cargo === +infoCargo.id_cargo);
        if (type === 1) {
            const file = event.target.files[0];
            if (file) {
                const fileType = getFileType(file);
                if (isFileTypeAllowed(fileType) && isFileSizeValid(file)) {
                    updData[idx] = { ...updData[idx], };
                    updData[idx][keyName] = file.name;
                    updData[idx][keyFile] = file;
                } else {
                    simpleAlerts('El archivo no es válido. Asegúrate de que sea del tipo y tamaño correctos.');
                }
            }
        } else {
            updData[idx][keyName] = null;
            updData[idx][keyFile] = null;
        }
        setData(updData);
    }

    const previewConformity = (idx, key) => {
        const fileUrl = URL.createObjectURL(data[idx][key]);
        setUrlDocument(fileUrl);
        handleOpen();
    }

    const saveCharge = async (info, typeAction, nameFile) => {
        try {
            handleOpenLoad();
            setTitleLoad('Guardando...');

            const array = info[nameFile].split('.');

            const ext = array[array?.length - 1];
            const mark = timeStampString();
            const formData = new FormData();

            let result;
            if (typeAction === 'conformidad') {
                const nameFile = `${info?.numdoc}${mark}-conf.${ext}`;
                const modifiedFile = new File([info.conformidadFile], nameFile, { type: info.conformidadFile.type });
                formData.append('myFile', modifiedFile);
                formData.append('id_cargo', info.id_cargo);
                formData.append('nameFile', nameFile);
                result = await ChargeService.uploadConformity(url, formData);
            }
            if (typeAction === 'devolucion') {
                const nameFile = `${info?.numdoc}${mark}-dev.${ext}`;
                const modifiedFile = new File([info.devolucionFile], nameFile, { type: info.devolucionFile.type });
                formData.append('myFile', modifiedFile);
                formData.append('id_cargo', info.id_cargo);
                formData.append('nameFile', nameFile);
                result = await ChargeService.uploadDevolucion(url, formData);
            }

            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveCharge: ${error}`);
        } finally {
            getChargeByUser();
            handleCloseLoad();
            setTitleLoad(null);
        }
    };

    const deleteConformity = async (info) => {
        try {
            handleOpenLoad();
            setTitleLoad('Borrando...');
            const result = await ChargeService.deleteConformity(url, info?.id_cargo);
            if (result.status === CODES.DELETE_204) {
                simpleAlerts('Conformidad borrada', 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el borrado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en deleteConformity: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
            getChargeByUser();
        }
    };

    const deleteDevolucion = async (info) => {
        try {
            handleOpenLoad();
            setTitleLoad('Borrando...');
            const result = await ChargeService.deleteDevolucion(url, info?.id_cargo);
            if (result.status === CODES.DELETE_204) {
                simpleAlerts('Devolución borrada', 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el borrado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en deleteDevolucion: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
            getChargeByUser();
        }
    };

    const saveDevolucion = async (info) => {
        info.fechaGeneracion = getDateOL();
        try {
            handleOpenLoad();
            setTitleLoad('Generando documento ...');

            const result = await ChargeService.saveDevolucion(url, info);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveCharge: ${error}`);
        } finally {
            getChargeByUser();
            handleCloseLoad();
            setTitleLoad(null);
        }
    };

    return (
        <div className='body-table table-responsive'>
            <SeeDocuments
                open={open}
                handleClose={handleClose}
                urlDocument={urlDocument}
            />
            <div className="container">
                <div className="body-table-wrapper mt-3">
                    <table className="table table-bordered table-hover table-sm">
                        <thead className="table-dark sticky-top">
                            <tr>
                                {columns?.map((col) => (
                                    <th
                                        scope="col"
                                        key={col.id}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`
                                        }}
                                    >
                                        {col.id === 'actions' ?
                                            <button type="button" className="btn btn-outline-light btn-sm mr-1 buttons-grid">
                                                <i className="fa-solid fa-user-pen"></i>
                                            </button>
                                            : col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((row, idxRow) => {
                                return (
                                    <tr key={idxRow}>
                                        {columns?.map((col) => {
                                            const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                            return (
                                                <td
                                                    key={col.id}
                                                    style={{
                                                        textAlign: col.align,
                                                        width: `${`${col.width}px`}px`,
                                                        maxWidth: `${`${col.width}px`}px`,
                                                    }}
                                                >
                                                    {col.id === 'devolucion' ?
                                                        row.devolucion ?
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-dark btn-sm"
                                                                    title='Ver devolucion'
                                                                    onClick={() => { handleOpen(); setUrlDocument(row.devolucion); }}
                                                                    style={{ padding: "0px", border: "none", }}
                                                                >
                                                                    <PageviewIcon sx={{ fontSize: '17px' }} />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-danger btn-sm"
                                                                    title='Eliminar devolucion'
                                                                    onClick={() => deleteDevolucion(row)}
                                                                    style={{ padding: "0px", border: "none", }}
                                                                >
                                                                    <DeleteIcon style={{ fontSize: '17px', }} />
                                                                </button>
                                                            </div>
                                                            : <button
                                                                type="button"
                                                                className="btn btn-outline-success btn-sm"
                                                                title='Generar cargo devolucion'
                                                                onClick={() => saveDevolucion(row)}
                                                                style={{ padding: "0px", border: "none", }}
                                                            >
                                                                <EngineeringIcon style={{ fontSize: '17px', }} />
                                                            </button>
                                                        : col.id === 'conformidad' ?
                                                            row.conformidad ?
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-dark btn-sm"
                                                                        title='Ver conformidad'
                                                                        onClick={() => { handleOpen(); setUrlDocument(row.conformidad); }}
                                                                        style={{ padding: "0px", border: "none", }}
                                                                    >
                                                                        <PageviewIcon sx={{ fontSize: '17px' }} />
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-danger btn-sm"
                                                                        title='Eliminar conformidad'
                                                                        onClick={() => deleteConformity(row)}
                                                                        style={{ padding: "0px", border: "none", }}
                                                                    >
                                                                        <DeleteIcon style={{ fontSize: '17px', }} />
                                                                    </button>
                                                                </div>
                                                                : (row.conformidadName ?
                                                                    <div>
                                                                        <button type="button"
                                                                            className="btn btn-outline-dark btn-sm"
                                                                            title="Previsualizar archivo"
                                                                            onClick={() => previewConformity(idxRow, 'conformidadFile')}
                                                                            style={{ padding: "0px", border: "none", }}
                                                                        >
                                                                            <PreviewIcon sx={{ fontSize: '17px' }} />
                                                                        </button>
                                                                        <button type="button"
                                                                            className="btn btn-outline-success btn-sm"
                                                                            title="Guardar conformidad"
                                                                            onClick={() => saveCharge(row, 'conformidad', 'conformidadName')}
                                                                            style={{ padding: "0px", border: "none", }}
                                                                        >
                                                                            <SaveIcon sx={{ fontSize: '17px' }} />
                                                                        </button>
                                                                        <button type="button"
                                                                            className="btn btn-outline-danger btn-sm"
                                                                            title="Eliminar archivo"
                                                                            onClick={(e) => handleFileChange(e, row, 0, { keyName: "conformidadName", keyFile: "conformidadFile" })}
                                                                            style={{ padding: "0px", border: "none", }}
                                                                        >
                                                                            <DeleteOutlineIcon sx={{ fontSize: '17px' }} />
                                                                        </button>
                                                                    </div>
                                                                    : <div>
                                                                        <input type="file" id={`fileInput-${row.id_cargo}`}
                                                                            accept=".pdf, .jpeg, .jpg, .png"
                                                                            name='conformidadFile' style={{ display: 'none' }}
                                                                            onChange={(e) => handleFileChange(e, row, 1, { keyName: "conformidadName", keyFile: "conformidadFile" })}
                                                                        />
                                                                        <button type="button" className="btn btn-outline-warning btn-sm mr-1 buttons-grid"
                                                                            title="Subir archivo"
                                                                            onClick={() => document.getElementById(`fileInput-${row.id_cargo}`).click()}
                                                                        >
                                                                            <CloudUploadIcon sx={{ fontSize: '1.02rem' }} />
                                                                        </button>
                                                                    </div>)
                                                            : col.id === 'ruta' ?
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-primary btn-sm"
                                                                    title='Ver cargo entregado'
                                                                    onClick={() => { handleOpen(); setUrlDocument(row.ruta); }}
                                                                    style={{ padding: "0px", border: "none", }}
                                                                >
                                                                    <ArticleIcon style={{ fontSize: '17px', }} />
                                                                </button>
                                                                : (col.type === 'number' && col.format) ? col.format(value) : value
                                                    }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <PaginationMain
                size={'small'}
                totalPages={totalPages}
                page={page}
                setPage={setPage}
            />
        </div>
    )
}

export default BodyConformity