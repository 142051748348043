import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class ChargeService {
    getCharge(url, queryParameters) {
        const ruta = `${API_URL}/charge?url=${url}`;
        const params = { ...queryParameters };
        return axios.get(ruta, {
            headers: headerRequest(),
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    saveCharge(url, body) {
        const ruta = `${API_URL}/charge?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteCharge(url, id) {
        const ruta = `${API_URL}/charge/${id}?url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getChargeByUser(url, queryParameters) {
        const ruta = `${API_URL}/charge/user?url=${url}`;
        const params = { ...queryParameters };
        return axios.get(ruta, {
            headers: headerRequest(),
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    uploadConformity(url, body) {
        const ruta = `${API_URL}/charge/conformity?url=${url}`;
        return axios.post(ruta, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...headerRequest(),
            },
        }).catch((error) => {
            return error;
        });
    }

    deleteConformity(url, id) {
        const ruta = `${API_URL}/charge/conformity/${id}?url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveDevolucion(url, body) {
        const ruta = `${API_URL}/return-equipment?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteDevolucion(url, id) {
        const ruta = `${API_URL}/return-equipment/${id}?url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    uploadDevolucion(url, body) {
        const ruta = `${API_URL}/return-equipment/conformidad?url=${url}`;
        return axios.post(ruta, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...headerRequest(),
            },
        }).catch((error) => {
            return error;
        });
    }
}

export default new ChargeService();
