import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class GeneralService {
    getMenu() {
        let ruta = `${API_URL}/menu`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getState() {
        let ruta = `${API_URL}/general/state`;
        return axios.get(ruta, {            
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getCostCenter() {
        let ruta = `${API_URL}/general/cost-center`;
        return axios.get(ruta, {            
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getUserType() {
        let ruta = `${API_URL}/general/user-type`;
        return axios.get(ruta, {            
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new GeneralService();
