import React from 'react';
import Cards from '../components/Card/Cards';
import '../../App.css'

const Home = () => {
    return (
       <div className='App-home'>
            <Cards/>
       </div> 
        
    )
};

export default Home;
