import React from "react";
import Card from "./Card";
import './cards.css';
import Inventario from '../../../assets/img/inventory.png';
import Charge from '../../../assets/img/cargos.png';
import Conformity from '../../../assets/img/conformity.png';
import Mantenimiento from '../../../assets/img/mantenimiento.png';
import { Global } from "../../../utils/general";

const cards = [
    {
        id: 1,
        title: 'INVENTARIO',
        image: Inventario,
        url: `${Global.path || ''}/inventory`
    },
    {
        id: 2,
        title: 'CARGOS',
        image: Charge,
        url: `${Global.path || ''}/documents`
    },
    {
        id: 3,
        title: 'CONFORMIDAD',
        image: Conformity,
        url: `${Global.path || ''}/conformity`
    },
    {
        id: 4,
        title: 'MANTENIMIENTO',
        image: Mantenimiento,
        url: `${Global.path || ''}/category`
    }

];
function Cards() {
    return (
        <div className="container container-home justify-content-center align-items-center">
            <div className="row justify-content-center align-items-center">
                {cards.map((card) => (
                    <div className="col-md-3 col-8 mb-4" key={card.id}>
                        <a href={card.url} className="card-link">
                            <Card title={card.title} imageSource={card.image} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Cards;
