import React, { useEffect, useState } from 'react'
import ProviderService from '../../../../services/provider';
import UsersService from '../../../../services/users';
import ExternalService from '../../../../services/external';
import InputMain from '../../../components/Main/InputMain';
import ModalMain from '../../../components/Main/ModalMain';
import SearchWorker from '../../../components/Charge/SearchItem/SearchWorker';
import InfoGeneral from '../../../components/User/AddUser/InfoGeneral';
import SelectMain from '../../../components/Main/SelectMain';
import { CODES } from '../../../../utils/httpCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const validationMessages = {
    clave: 'Debe ingresar una clave (Mínimo 8 caracteres, 1 número, 1 mayuscula, 1 caracter especial ($!%*&#))!',
    usuario: 'Debe ingresar un usuario!',
    persona_id: 'Debe seleccionar un trabajador!',
    perfil: 'Debe seleccionar un perfil!',
    proveedor: 'Debe seleccionar un proveedor!',
};

const AddUser = (props) => {
    const { open, handleClose, setTitleLoad, handleOpenLoad, handleCloseLoad, formatNewUser,
        state, profile, typeUser, user, setUser, getUsers,
    } = props;
    const url = window.location.pathname;
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*&#])[A-Za-z\d@$!%*&#]{8,}$/;

    const [dataWorkers, setDataWorkers] = useState(null);
    const [viewWorkers, setViewWorkers] = useState(true);
    const [search, setSearch,] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 5;
    const [totalPages, setTotalPages] = useState(1);
    const [activeTab, setActiveTab] = useState(null);
    const [selectTypeUser, setSelectTypeUser] = useState(null);
    const [providers, setProviders] = useState(null)
    const [selectProvider, setSelectProvider] = useState(null)

    const handleTabClick = (tabIndex, event) => {
        event.preventDefault();
        setActiveTab(tabIndex);
        return setSelectTypeUser(tabIndex);
    };

    const cleanData = () => {
        return setDataWorkers(null),
            setSearch, (null),
            setActiveTab(null),
            setSelectTypeUser(null),
            setProviders(null),
            setSelectProvider(null),
            setUser(null),
            setViewWorkers(false),
            handleClose();
    }

    useEffect(() => {
        if (!user?.id_usuario && selectTypeUser === 1)
            getWorker();
        if (selectTypeUser === 2)
            getProviders();
    }, []);
    useEffect(() => {
        if (user && selectTypeUser === 1)
            getWorker();
        if (selectTypeUser === 2)
            getProviders();
    }, [selectTypeUser]);
    useEffect(() => {
        const tmpProvider = providers?.find((e) => e.id_proveedor === selectProvider);
        if (tmpProvider) {
            let updUser = _.cloneDeep(user);
            updUser.usuario = tmpProvider?.documento.trim();
            updUser.nombres = tmpProvider?.nombre;
            updUser.numdoc = tmpProvider?.documento;
            updUser.email = tmpProvider?.email;
            updUser.proveedor = tmpProvider?.id_proveedor;
            setUser(updUser);
        }
    }, [selectProvider]);
    useEffect(() => {
        setActiveTab(user?.tipoUsuario?.id_tipoUsuario || user?.tipoUsuario || 1);
        setSelectTypeUser(user?.tipoUsuario?.id_tipoUsuario || user?.tipoUsuario || 1);
        setSelectProvider(user?.proveedor?.id_proveedor || user?.proveedor || null);
    }, [user?.tipoUsuario]);
    useEffect(() => {
        if (selectTypeUser !== user?.tipoUsuario?.id_tipoUsuario)
            setUser(formatNewUser)
    }, [selectTypeUser]);

    const getWorker = async () => {
        if (user?.id_usuario)
            return;
        try {
            handleOpenLoad();
            setTitleLoad('Buscando trabajadores ...');
            const result = await ExternalService.getWorker(url, { search: (search || ''), page: page, pageSize: pageSize, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.data?.length === 0) {
                    simpleAlerts('No se encontro trabajador con la palabra clave!', 'error');
                }
                if (result?.data?.data?.length === 1) {
                    setViewWorkers(false);
                    selectWorker(result?.data?.data[0]);
                    return;
                } else {
                    setViewWorkers(true);
                }
                setDataWorkers(result?.data?.data);
                setTotalPages(result?.data?.last_page);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getWorker: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const selectWorker = (info) => {
        let updUser = _.cloneDeep(user);
        updUser.usuario = `${info?.nombres?.substring(0, 1)}${info?.apePaterno}`.toLocaleLowerCase();
        updUser.apellidos = info?.nombres;
        updUser.nombres = `${info?.apePaterno} ${info?.apeMaterno}`;
        updUser.numdoc = info.numdoc;
        updUser.email = info.email;
        updUser.persona_id = info.id_persona;
        setViewWorkers(false);
        return setUser(updUser);
    }

    const getProviders = async () => {
        try {
            const result = await ProviderService.getProviders(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_proveedor;
                    element.label = element.nombre;
                    element.value = element.id_proveedor;
                })
                setProviders(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getProviders: ${error}`);
        }
    }

    const saveUser = async (e) => {
        e.preventDefault();
        if (!(user?.id_usuario && (user?.clave?.trim() === '' || !user?.clave?.trim())) && !regex.test(user.clave)) {
            simpleAlerts(validationMessages.clave);
            return;
        }
        if (!user.usuario || user.usuario === '') {
            simpleAlerts(validationMessages.usuario);
            return;
        }
        if ((!user.persona_id || 
              user.persona_id === '' || 
              user.persona_id === 0) && 
            selectTypeUser === 1 &&
            !user.externos
            ) {
            simpleAlerts(validationMessages.persona_id);
            return;
        }
        if ((!user.proveedor || user.proveedor === 0) && selectTypeUser === 2) {
            simpleAlerts(validationMessages.proveedor);
            return;
        }
        if (!user.perfil || user.perfil === '') {
            simpleAlerts(validationMessages.perfil);
            return;
        }

        const tmpUser = _.cloneDeep(user);
        tmpUser.tipoUsuario = selectTypeUser;
        handleOpenLoad();
        setTitleLoad('Guardando...');
        try {
            const result = await UsersService.saveUser(url, tmpUser);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                getUsers();
                handleClose();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                handleCloseLoad();
                setTitleLoad(null);
            }
            return [];
        } catch (error) {
            handleCloseLoad();
            setTitleLoad(null);
            console.log(`Error en saveProviders: ${error}`);
        }
    }

    return (
        <ModalMain
            size={'sm'}
            open={open}
            handleClose={cleanData}
            formSubmit={saveUser}
            title={'Mantenimiento de Usuarios'}
            subTitle={'Creación y edición de usuarios'}
            body={
                <div>
                    <ul className="nav nav-tabs justify-content-center">
                        {typeUser?.map((item, idx) => {
                            return (
                                <li className="nav-item" key={`nav-item-${idx}`}>
                                    <a
                                        className={`nav-link ${activeTab === item.id_tipoUsuario ? 'active' : ''}`}
                                        onClick={(e) => handleTabClick(item.id_tipoUsuario, e)}
                                        href="#"
                                    >
                                        {item?.destipoUsuario?.toUpperCase()}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    {selectTypeUser === 2 ?
                        <div className={`animate__animated animate__fadeInDown`}>
                            <div className='container'>
                                <SelectMain
                                    title={'Proveedor'}
                                    titleColor={'black'}
                                    placeholder={'Seleccionar proveedor'}
                                    width={'100%'}
                                    options={providers}
                                    value={+selectProvider}
                                    name={'proveedor'}
                                    onChange={(value, event) => { setSelectProvider(value); console.log('value', value) }}
                                />
                            </div>
                            <InfoGeneral
                                state={state}
                                profile={profile}
                                typeUser={typeUser}
                                user={user}
                                setUser={setUser}
                            />
                        </div>
                        : (viewWorkers && !user?.id_usuario) ?
                            <SearchWorker
                                data={dataWorkers}
                                search={search}
                                setSearch={setSearch}
                                page={page}
                                setPage={setPage}
                                totalPages={totalPages}
                                getWorker={getWorker}

                                setInfoWorker={selectWorker}
                            />
                            : <div className={`animate__animated animate__backInDown`}>
                                {!user?.id_usuario &&
                                    <div className='container d-flex align-items-end justify-content-center'>
                                        <InputMain
                                            title={'Buscar equipo'}
                                            placeholder={'Buscar trabajador (nombre, codigo)'}
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <button type="button" className="btn btn-outline-dark btn-sm mb-1 btn-group-custom"
                                            onClick={getWorker}
                                        ><i className="fa-solid fa-magnifying-glass"></i></button>
                                    </div>}
                                <InfoGeneral
                                    state={state}
                                    profile={profile}
                                    typeUser={typeUser}
                                    user={user}
                                    setUser={setUser}
                                />
                            </div>
                    }
                </div>
            }
            buttonActions={
                [
                    <button type="submit" className="btn btn-primary" key={"btn-save-mdl"}>Guardar</button>
                ]}
        />
    );
}

export default AddUser