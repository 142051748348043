import React from "react";
import { useReactToPrint } from "react-to-print";

import Barcode from "./Barcode";
import BarcodeMasive from "./BarcodeMasive";
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

/*const PrintBarCodeMasive = ({ resultSearch}) => {
   
  const codigosYRuc = resultSearch?.map(item => ({
    codigoBarras: item.codigoBarras,
    rucEmpresa: item.rucEmpresa
  })) || [];

    const barcodeMasiveRef = React.useRef();
    
    const handlePrint = useReactToPrint({
        content: () => barcodeMasiveRef.current,
        pageStyle: `
            @page {
                size: 2in 1in portrait; 
                margin: 0;
               
            }
        
            body {
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
        `,
    });

    return (
      <div>
        <div style={{ display: 'none' }}>
          <BarcodeMasive ref={barcodeMasiveRef} codigosYRuc={codigosYRuc} />
        </div>
        <button
          type="button"
          className="btn btn-warning mx-4"
         
          onClick={handlePrint}
        >
          Códigos Barras Masivo
        </button>
      </div>
    );
  };

export default PrintBarCodeMasive;
*/

const PrintBarCodeMasive = ({ selectedItems, onPrintSelected }) => {
  const barcodeMasiveRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => barcodeMasiveRef.current,
        pageStyle: `
            @page {
                size: 2in 1in portrait; 
                margin: 0;
               
            }
        
            body {
                display: flex;
                align-items: center;
                justify-content: center;
                
            }
        `,
  });

  return (
    <div>
      <div style={{ display: 'none' }}>
        {/* Pasa selectedItems a BarcodeMasive */}
        <BarcodeMasive ref={barcodeMasiveRef} selectedItems={selectedItems} />
      </div>
      <button
        type="button"
        className="btn btn-warning"
        title="Imprimir CB"
        onClick={() => {
          // Invoca la función de impresión con los elementos seleccionados
          onPrintSelected(selectedItems);
          // Además, puedes ejecutar la impresión directamente aquí
          handlePrint();
        }}
      >
       <ViewWeekIcon />
      </button>
    </div>
  );
};

export default PrintBarCodeMasive;
