import React, { useState, useEffect } from 'react'
import ModalMain from '../../../components/Main/ModalMain';
import InfoGeneral from '../../../components/Buy/AddInformationBuy/InfoGeneral';
import BuyService from '../../../../services/buy';
import { CODES } from '../../../../utils/httpCodes';
import { simpleAlerts } from '../../../../utils/alerts';

const AddInformationBuy = (props) => {
    const { open, handleClose, setTitleLoad, handleOpenLoad, handleCloseLoad, cecos, worker, setWorker, equipment, setEquipment, listEquipment, } = props;

    const url = window.location.pathname;

    const [infoBuy, setInfoBuy] = useState(null);

    useEffect(() => {
        const info = {
            persona_id: worker?.id_persona,
            trabajador: worker?.trabajador,
            numdoc: worker?.numdoc,
            email: worker?.email,
            direccion: worker?.direccion,
            telefono: worker?.telefono,
            puesto: worker?.id_puesto,
            desPuesto: worker?.desPuesto,
            rucEmpresa: worker?.rucEmpresa,
            empresa_id: worker?.id_empresa,
            desEmpresa: worker?.desEmpresa,

            equipo: equipment?.equipo?.id_equipo,
            nombreEquipo: equipment?.equipo?.nombre,
            marca: equipment?.equipo?.marca,
            modelo: equipment?.equipo?.modelo,
            nroSerie: equipment?.equipo?.nroSerie,
            imei: equipment?.equipo?.imei,
            valor: equipment?.equipo?.valor,
            desTipoEquipo: equipment?.equipo?.tipoEquipo?.desTipoEquipo,
            valorRemate: 0.00,
            cargo: equipment?.id_cargo,
            centroCosto: equipment?.centroCosto?.id_centroCosto,
        };

        return setInfoBuy(info);
    }, [worker, equipment]);

    const cleanData = () => {
        setWorker(null);
        setEquipment(null);
        handleClose();
    }

    const saveBuyEquipment = async (e) => {
        e.preventDefault();

        handleOpenLoad();
        setTitleLoad('Guardando...');
        try {
            const result = await BuyService.saveBuyEquipment(url, infoBuy);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                handleClose();
                listEquipment(worker)
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveBuyEquipment: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    return (
        <ModalMain
            size={'md'}
            open={open}
            handleClose={cleanData}
            formSubmit={saveBuyEquipment}
            title={'Compra de equipos'}
            subTitle={'Información general de la compra'}
            body={
                <InfoGeneral
                    cecos={cecos}
                    infoBuy={infoBuy}
                    setInfoBuy={setInfoBuy}
                />
            }
            buttonActions={[
                <button type="submit" className="btn btn-primary" key={"btn-save-mdl"}>Guardar</button>
            ]}
        />
    );
}

export default AddInformationBuy