import React from 'react';
import InfoMain from '../../Main/InfoMain';
import InputMain from '../../Main/InputMain';
import SelectMain from '../../Main/SelectMain';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const InfoGeneral = ({ state, provider, setProvider, searchRUC, RUC, setRUC }) => {

    
    return (
        <div className="container" style={{ maxHeight: '40vh', overflow: 'scroll' }}>
            <div className="row">
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <div className='d-flex align-items-end justify-content-center'>
                        <InputMain
                            title={'Documento'}
                            titleColor={'black'}
                            placeholder={'documento'}
                            value={RUC || ''}
                            name={'documento'}
                            onChange={(e) => setRUC(e.target.value)}
                            required={true}
                        />
                        <button type="button" className="btn btn-outline-dark btn-sm mb-1 mx-1"
                            title='Ver clave'
                            onClick={() => searchRUC(provider?.documento)}
                        >
                            <TravelExploreIcon sx={{ fontSize: '1.02rem' }} />
                        </button>
                    </div>
                </div>
                <div className='col-sm-12'>
                    <InfoMain
                        title={'Nombre'}
                        titleColor={'black'}
                        placeholder={'Razon social proveedor'}
                        value={provider?.nombre || ''}
                    />
                </div>

                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Nro(s). Telefono'}
                        titleColor={'black'}
                        placeholder={'telefono'}
                        value={provider?.telefono || ''}
                        name={'telefono'}
                        onChange={(e) => setProvider({ ...provider, telefono: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Direccion'}
                        titleColor={'black'}
                        placeholder={'Dirección del proveedor'}
                        value={provider?.direccion || ''}
                        name={'direccion'}
                        onChange={(e) => setProvider({ ...provider, direccion: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Correo'}
                        titleColor={'black'}
                        placeholder={'email'}
                        value={provider?.email || ''}
                        name={'email'}
                        onChange={(e) => setProvider({ ...provider, email: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <SelectMain
                        title={'Estado'}
                        titleColor={'black'}
                        placeholder={'Seleccionar estado'}
                        width={'100%'}
                        options={state}
                        value={provider?.estado?.id_estado || provider?.estado || 0}
                        name={'state'}
                        onChange={(value, e) => setProvider({ ...provider, estado: value })}
                    />
                </div>
               
            </div>
        </div>
    )
}

export default InfoGeneral