import React, { useState, useEffect } from 'react'
import ModalMain from '../../../components/Main/ModalMain';
import InfoGeneral from '../../../components/Provider/AddProvider/InfoGeneral';
import ExternalService from '../../../../services/external';
import ProviderService from '../../../../services/provider';
import { CODES } from '../../../../utils/httpCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';


const AddProvider = (props) => {
    const { open, handleClose, setTitleLoad, handleOpenLoad, handleCloseLoad, state, provider, setProvider, getProviders, } = props;

    const url = window.location.pathname;
    const [RUC, setRUC] = useState(null);
    const [infoApi, setInfoApi] = useState(null);

    useEffect(() => {
        if (open)
            setRUC(provider?.documento);
    }, [open]);

    useEffect(() => {
        if (!infoApi) {
            return
        }
        if (infoApi?.condicion !== 'HABIDO') {
            simpleAlerts('Lo sentimos proveedor no esta habilitado!!', 'warning');
            return
        }
        let updProvider = _.cloneDeep(provider);
        updProvider.nombre = infoApi?.nombre;
        updProvider.direccion = infoApi?.direccion;
        updProvider.documento = infoApi?.numeroDocumento;
        setProvider(updProvider);
    }, [infoApi]);

    const cleanData = () => {
        setRUC(null)
        setInfoApi(null);
        setProvider(null);
        handleClose();
    }

    const searchRUC = async () => {
        try {
            setInfoApi(null);
            let updProvider = _.cloneDeep(provider);
            updProvider.nombre = null;
            updProvider.direccion = null;
            updProvider.documento = null;
            setProvider(updProvider);

            handleOpenLoad();
            setTitleLoad('Buscando...');
            const result = await ExternalService.apiRUC(url, RUC);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data?.error) {
                    simpleAlerts(result.data?.error, 'error');
                    return
                }
                if (!result.data?.nombre) {
                    simpleAlerts('No se encontro información!!');
                    return
                }
                setInfoApi(result.data);
            } else {

            }
        } catch (error) {
            console.log(`Error en getProviders: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    };

    const saveProvider = async (e) => {
        e.preventDefault();

        handleOpenLoad();
        setTitleLoad('Guardando...');
        try {
            const result = await ProviderService.saveProviders(url, provider);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.saveInfo?.message || 'Guardado', 'success');
                getProviders();
                handleClose();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                handleCloseLoad();
                setTitleLoad(null);
            }
            return [];
        } catch (error) {
            handleCloseLoad();
            setTitleLoad(null);
            console.log(`Error en saveProviders: ${error}`);
        }
    }

    return (
        <ModalMain
            size={'sm'}
            open={open}
            handleClose={cleanData}
            formSubmit={saveProvider}
            title={'Mantenimiento de Proveedores'}
            subTitle={'Creación y edición de proveedores'}
            body={
                <InfoGeneral
                    state={state}
                    provider={provider}
                    setProvider={setProvider}
                    searchRUC={searchRUC}
                    RUC={RUC}
                    setRUC={setRUC}
                />
            }
            buttonActions={[
                <button type="submit" className="btn btn-primary" key={"btn-save-mdl"}>Guardar</button>
            ]}
        />
    );
}

export default AddProvider