import React, { useState } from 'react'
import EquipmentService from '../../../../services/equipment';
import ModalMain from '../../../components/Main/ModalMain';
import InfoGeneral from '../../../components/Equipment/AddEquipment/InfoGeneral';
import InfoGeneralAll from '../../../components/Equipment/AddEquipment/InfoGeneralAll';
import { CODES } from '../../../../utils/httpCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const validationMessages = {
    nombre: 'Debe ingresar un nombre!',
    nroSerie: 'Debe ingresar un nro de serie!',
    valor: 'Debe ingresar un valor mayor a 0!',
    fechaAdq: 'Debe ingresar una fecha de adquisición!',
    proveedor: 'Debe seleccionar un proveedor!',
    categoria: 'Debe seleccionar una categoria!',
    estadoEqp: 'Debe seleccionar un estado de equipo!',
    tipoEquipo: 'Debe seleccionar un tipo de equipo!',
    empresa: 'Debe selecionar una empresa!',
};

const AddEquipment = (props) => {
    const { open, handleClose, handleOpenLoad, handleCloseLoad,
        setTitleLoad, companies, getEquipment, dataCategory, providers, equipmentState, equipmentType, equipment, setEquipment,
        informationAll, setInformationAll, } = props;

    const url = window.location.pathname;
    const dataCategoryEdit = dataCategory?.slice(1);
    const dataCompaniesEdit = companies?.slice(1);
    const dataEquipmentTypeEdit = equipmentType?.slice(1);
    const [detailDelete, setDetailDelete] = useState([]);

    const cleanData = () => {
        setEquipment(null);
        setInformationAll(null);
        setDetailDelete([]);
        handleClose();
    }

    const saveEquipment = async () => {
        let updEquipment = _.cloneDeep(equipment);
        if (!updEquipment.nombre || updEquipment.nombre === 0) {
            simpleAlerts(validationMessages.nombre);
            return
        }
        if (!updEquipment.categoria || updEquipment.categoria === 0) {
            simpleAlerts(validationMessages.categoria);
            return
        }
        if (!updEquipment.proveedor || updEquipment.proveedor === 0) {
            simpleAlerts(validationMessages.proveedor);
            return
        }
        if (!updEquipment.fechaAdq || updEquipment.fechaAdq === '') {
            simpleAlerts(validationMessages.fechaAdq);
            return
        }
        if (!updEquipment.valor || updEquipment.valor <= 0 || updEquipment.valor === '') {
            simpleAlerts(validationMessages.valor);
            return
        }
        if (!updEquipment.nroSerie || updEquipment.nroSerie === 0) {
            simpleAlerts(validationMessages.nroSerie);
            return
        }
        if (!updEquipment.estadoEqp || updEquipment.estadoEqp === 0) {
            simpleAlerts(validationMessages.estadoEqp);
            return
        }
        if (!updEquipment.tipoEquipo || updEquipment.tipoEquipo === 0) {
            simpleAlerts(validationMessages.tipoEquipo);
            return
        }
        if (!updEquipment.empresa_id || updEquipment.empresa_id === 0) {
            simpleAlerts(validationMessages.empresa);
            return
        }

        updEquipment.desEmpresa = companies?.find(e => +e.id_empresa === +updEquipment.empresa_id)?.desEmpresa;
        updEquipment.rucEmpresa = companies?.find(e => +e.id_empresa === +updEquipment.empresa_id)?.rucEmpresa;

        updEquipment.deleteDetalle = detailDelete;
        handleOpenLoad();
        setTitleLoad('Guardando...');
        try {
            const result = await EquipmentService.saveEquipment(url, updEquipment);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                getEquipment();
                handleClose();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                handleCloseLoad();
                setTitleLoad(null);
            }
            return [];
        } catch (error) {
            handleCloseLoad();
            setTitleLoad(null);
            console.log(`Error en saveEquipment: ${error}`);
        }
    }

    return (
        <ModalMain
            size={'lg'}
            open={open}
            handleClose={cleanData}
            title={'Mantenimiento de Equipos'}
            subTitle={'Creación y edición de equipos'}
            body={
                informationAll ?
                    <InfoGeneralAll
                        informationAll={informationAll}
                    />
                    : <InfoGeneral
                        companies={dataCompaniesEdit}
                        dataCategory={dataCategoryEdit}
                        providers={providers}
                        equipmentState={equipmentState}
                        equipmentType={dataEquipmentTypeEdit}


                        equipment={equipment}
                        setEquipment={setEquipment}
                        detailDelete={detailDelete}
                        setDetailDelete={setDetailDelete}
                    />
            }
            buttonActions={
                !informationAll &&
                [
                    <button type="button" className="btn btn-primary" key={"btn-save-mdl"} onClick={saveEquipment}>Guardar</button>
                ]
            }
        />
    );
}

export default AddEquipment