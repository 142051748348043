import React, { useState, useEffect } from 'react'
import ProviderService from '../../../services/provider';
import GeneralService from '../../../services/general';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import BodyProvider from '../../components/Provider/BodyProvider';
import AddProvider from './AddProvider/AddProvider';
import InputMain from '../../components/Main/InputMain';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/httpCodes';

const newProvider = {
    "nombre": "",
    "direccion": "",
    "telefono": "",
    "email": "",
    "documento": "",
    "estado": 1,
}

const Provider = () => {
    const url = window.location.pathname;
    const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
    const [openLoad, setOpenLoad] = useState(true);
    const handleOpenLoad = () => setOpenLoad(true);
    const handleCloseLoad = () => setOpenLoad(false);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [search, setSearch] = useState("");
    const [state, setState] = useState(null)
    const [provider, setProvider] = useState(null)
    const [providers, setProviders] = useState(null)

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        Promise.all([
            getState(),
            getProvidersAll(),
        ]).then(() => { handleCloseLoad(); setTitleLoad(null) });
    }

    const getState = async () => {
        try {
            const result = await GeneralService.getState(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_estado;
                    element.label = element.desEstado;
                    element.value = element.id_estado;
                })
                setState(result.data);
            } else {
                simpleAlerts('No hay estados', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getState: ${error}`);
        }
    }

    const getProvidersAll = async () => {
        try {
            handleOpenLoad();
            setTitleLoad('Listando proveedores ...');
            const result = await ProviderService.getProvidersAll(url);
            if (result.status === CODES.SUCCESS_200) {
                setProviders(result.data);
            } else {
                simpleAlerts('No se encontro proveedores', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getProviders: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const viewProvider = (info) => {
        setProvider(info);
        handleOpen();
    }

    return (
        <div className='container'>
            <SpinnerLoad open={openLoad} title={titleLoad} />
            <AddProvider
                open={open}
                handleClose={handleClose}
                setTitleLoad={setTitleLoad}
                handleOpenLoad={handleOpenLoad}
                handleCloseLoad={handleCloseLoad}

                state={state}
                provider={provider}
                setProvider={setProvider}

                getProviders={getProvidersAll}
            />
            <div className="container">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <InputMain
                            title={'Buscar proveedor'}
                            placeholder={'nombre, documento'}
                            width={'50%'}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="d-flex justify-content-center align-items-end">
                            <button type="button" className="btn btn-warning mx-4"
                                onClick={() => viewProvider(newProvider)}
                            >Crear Proveedor</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-md-10'>
                    <BodyProvider
                        handleOpenLoad={handleOpenLoad}
                        handleCloseLoad={handleCloseLoad}
                        setTitleLoad={setTitleLoad}
                        getProviders={getProvidersAll}
                        data={providers}
                        search={search}
                        viewProvider={viewProvider}
                    />
                </div>
            </div>

        </div>
    )
}

export default Provider