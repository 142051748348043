import React, { useState, useEffect } from 'react'
import ExternalService from '../../../services/external';
import CategoryService from '../../../services/category';
import ProviderService from '../../../services/provider';
import EquipmentService from '../../../services/equipment';
import EquipmentStateService from '../../../services/equipment-state';
import EquipmentTypeService from '../../../services/equipment-type';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import SelectMain from '../../components/Main/SelectMain';
import AddEquipment from './AddEquipment/AddEquipment';
import BodyEquipment from '../../components/Equipment/BodyEquipment';
import InputMain from '../../components/Main/InputMain';
import { filterData, signOut, validateUser } from '../../../utils/function';
import { simpleAlerts } from '../../../utils/alerts';
import { CODES } from '../../../utils/httpCodes';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PrintBarCodeMasive from '../../components/Equipment/PrintBarCodeMasive';

const newEquipment = {
  nombre: '',
  caracteristicas: '',
  marca: '',
  modelo: '',
  nroSerie: '',
  imei: '',
  valor: 0.00,
  fechaAdq: '',
  proveedor: '',
  categoria: '',
  estadoEqp: 1,
  tipoEquipo: '',
  estado: 1,
  detalleEquipo: [],
  empresa_id: null,
  desEmpresa: '',
  rucEmpresa: '',
}

const Equipment = () => {
  const url = window.location.pathname;
  const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
  const [openLoad, setOpenLoad] = useState(true);
  const handleOpenLoad = () => setOpenLoad(true);
  const handleCloseLoad = () => setOpenLoad(false);
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dataCategory, setDataCategory] = useState(null)
  const [category, setCategory] = useState(null)
  const [providers, setProviders] = useState(null)
  const [equipmentState, setEquipmentState] = useState(null)
  const [equipmentType, setEquipmentType] = useState(null)
  const [companies, setCompanies] = useState(null);
  const [valueCompany, setValueCompany] = useState(null);
  const [valueEquipmentType, setValueEquipmentType] = useState(null);

  const [equipment, setEquipment] = useState(null)
  const [dataEquipment, setDataEquipment] = useState(null)
  const [dataFilter, setDataFilter] = useState(null)
  const [searchS, setSearchS] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const [totalPages, setTotalPages] = useState(1);

  let resultSearch = filterData(dataFilter, searchS, ['nombre', 'nroSerie', 'imei']);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [informationAll, setInformationAll] = useState(null);

  useEffect(() => {
    setDataEquipment(null);
  }, [category, valueCompany, valueEquipmentType, search]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getEquipment();
  }, [page]);

  const init = async () => {
    Promise.all([
      getEmpresas(),
      getEquipmentState(),
      getEquipmentType(),
      getCategory(),
      getProviders(),
      getEquipment(),
      getEmpresas()
    ]).then(() => { handleCloseLoad(); setTitleLoad(null) });
  }

  const handlePrintSelected = () => {
    // Lógica para imprimir con los elementos seleccionados
    // console.log("Elementos seleccionados:", selectedItems);
    // Llamar a la función de impresión con selectedItems
    // imprimirCodigosBarras(selectedItems);
  };

  const getEmpresas = async () => {
    try {
      const result = await ExternalService.getEmpresas();
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.value = element.id_empresa
          element.label = element.desEmpresa
        })
        let tmp = result.data;
        tmp.unshift({ id: -1, value: -1, label: 'Todas' });
        setCompanies(tmp);
      } else {
        simpleAlerts('No hay información de empresas', 'info');
      }
      return [];
    } catch (error) {
      console.log(`Error en getEmpresas: ${error}`);
    }
  }

  const getCategory = async () => {
    try {
      const result = await CategoryService.getCategory(url);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.id = element.id_categoria;
          element.label = element.nombre;
          element.value = element.id_categoria;
        })
        let tmp = result.data;
        tmp.unshift({ id: -1, value: -1, label: 'Todas' });
        setDataCategory(tmp);
      } else {
        simpleAlerts('No hay categorias', 'info');
      }
      return []
    } catch (error) {
      console.log(`Error en getCategory: ${error}`);
    }
  }

  const getProviders = async () => {
    try {
      const result = await ProviderService.getProviders(url);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.id = element.id_proveedor;
          element.label = element.nombre;
          element.value = element.id_proveedor;
        })
        setProviders(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en getProviders: ${error}`);
    }
  }

  const getEquipmentState = async () => {
    try {
      const result = await EquipmentStateService.getEquipmentState(url);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.id = element.id_estadoEqp;
          element.label = element.descripcion;
          element.value = element.id_estadoEqp;
        })
        setEquipmentState(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en getProviders: ${error}`);
    }
  }

  const getEquipmentType = async () => {
    try {
      const result = await EquipmentTypeService.getEquipmentType(url);
      if (result.status === CODES.SUCCESS_200) {
        result.data?.forEach(element => {
          element.id = element.id_tipoEquipo;
          element.label = element.desTipoEquipo;
          element.value = element.id_tipoEquipo;
        })
        let tmp = result.data;
        tmp.unshift({ id: -1, value: -1, label: 'Todas' });
        setEquipmentType(tmp);
      }
      return []
    } catch (error) {
      console.log(`Error en getProviders: ${error}`);
    }
  }

  const getEquipment = async () => {
    try {
      handleOpenLoad();
      setTitleLoad('Buscando equipos ...')
      const params = {
        empresa_id: valueCompany,
        tipoEquipo: valueEquipmentType,
        categoria: category
      };
      const result = await EquipmentService.getEquipment(
        url,
        params,
        { search: search, page: page, pageSize: pageSize, pagination: 1 }
      );
      setDataEquipment(null);
      setTotalPages(null);
      if (result.status === CODES.SUCCESS_200) {
        setDataEquipment(result.data[0]);
        setTotalPages(result.data[1]?.totalPages);
      } else {
        simpleAlerts('No se encontro equipo', 'info');
      }
      return []
    } catch (error) {
      console.log(`Error en getEquipment: ${error}`);
    } finally {
      handleCloseLoad();
      setTitleLoad(null)
    }
  }

  const viewDetailEquipment = (info) => {
    setEquipment(info);
    handleOpen();
  }

  const generarCodigoBarras = async () => {
    try {
      handleOpenLoad();
      setTitleLoad("Generando Codigos de barras ...");
      const result = await EquipmentService.generarCodigoBarras(url);
      if (result.status === CODES.SUCCESS_200) {
        simpleAlerts('Codigo de barras generado', 'success');
      } else {
        simpleAlerts("No se puedo generar codigos", "info");
      }
      return [];
    } catch (error) {
      console.log(`Error en generarCodigoBarras: ${error}`);
    } finally {
      handleCloseLoad();
      setTitleLoad(null);
    }
  };

  return (
    <div className='container'>
      <SpinnerLoad open={openLoad} title={titleLoad} />
      <AddEquipment
        open={open}
        handleClose={handleClose}
        handleOpenLoad={handleOpenLoad}
        handleCloseLoad={handleCloseLoad}
        setTitleLoad={setTitleLoad}

        companies={companies}
        getEquipment={getEquipment}

        dataCategory={dataCategory}
        providers={providers}
        equipmentState={equipmentState}
        equipmentType={equipmentType}

        equipment={equipment}
        setEquipment={setEquipment}

        informationAll={informationAll}
        setInformationAll={setInformationAll}
      />
      <div className="container">
        <div className="row">
          <div className="col-6">
            <SelectMain
              title={'Empresa'}
              placeholder={'Seleccionar empresa de busqueda'}
              options={companies}
              value={valueCompany || -1}
              onChange={(value, event) => setValueCompany(value)}
            />
          </div>
          <div className="col-3">
            <SelectMain
              title={'Tipo'}
              placeholder={'Seleccionar tipo de equipo de busqueda'}
              options={equipmentType}
              value={valueEquipmentType || -1}
              onChange={(value, event) => setValueEquipmentType(value)}
            />
          </div>
          <div className="col-3">
            <SelectMain
              title={'Categoria'}
              placeholder={'Seleccionar categoria de busqueda'}
              options={dataCategory}
              value={category || -1}
              onChange={(value, event) => setCategory(value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <div className='d-flex align-items-end'>
              <InputMain
                title={'Buscar equipo'}
                placeholder={'Nro seria, nombre, código de barras'}
                width={'100%'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button type="button" className="btn btn-outline-light btn-group-custom" onClick={() => getEquipment()}><i className="fa-solid fa-magnifying-glass"></i></button>
            </div>
          </div>
          <div className="col d-flex justify-content-center justify-content-md-end align-items-end gap-3">
            <button
              type="button"
              className="btn btn-warning"
              title="Agregar Equipo"
              onClick={() => viewDetailEquipment(newEquipment)}
            ><ImportantDevicesIcon />
            </button>
            <button
              type="button"
              className="btn btn-warning"
              title="Generar CB"
              onClick={() => generarCodigoBarras()}
            ><ReceiptIcon />
            </button>
            <PrintBarCodeMasive
              selectedItems={selectedItems}
              onPrintSelected={handlePrintSelected}
            />
          </div>
        </div>
      </div>
      <BodyEquipment
        handleOpenLoad={handleOpenLoad}
        handleCloseLoad={handleCloseLoad}
        setTitleLoad={setTitleLoad}

        getEquipment={getEquipment}
        data={dataEquipment}
        viewDetailEquipment={viewDetailEquipment}
        resultSearch={resultSearch}
        searchS={searchS}

        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectAll={selectAll}
        setSelectAll={setSelectAll}

        totalPages={totalPages}
        page={page}
        setPage={setPage}

        handleOpen={handleOpen}
        setInformationAll={setInformationAll}
      />
    </div>
  )
}

export default Equipment