import React from 'react';
import InputMain from '../../Main/InputMain';
import PaginationMain from '../../Main/PaginationMain';
import _ from 'lodash';
import { filterData } from '../../../../utils/function';

const columns = [
    { id: 'check', label: 'Sel', width: 50, align: 'center', },
    { id: 'codSghh', label: 'codigo', width: 80, align: 'center', },
    { id: 'trabajador', label: 'Nombre', width: 200, align: 'left', },
];
const columnsMap = {
    estado_id: 'estado.desEstado',
    estadoEqp_id: 'estadoEqp.descripcion',
    proveedor_id: 'proveedor.nombre',
    categoria_id: 'categoria.nombre'
};
function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const SearchWorker = ({ maxHeight = 50, data, search, setSearch, page, setPage, totalPages, getWorker, setInfoWorker }) => {
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            getWorker();
        }
    };
    return (
        <div className="container animate__animated animate__backInDown" style={{ maxHeight: `${maxHeight}vh`, overflow: 'scroll' }}>
            <div className='d-flex align-items-end'>
                <InputMain
                    title={'Buscar equipo'}
                    placeholder={'Buscar trabajador (nombre, codigo)'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button type="button" className="btn btn-outline-dark btn-sm mb-1 btn-group-custom" onClick={getWorker}><i className="fa-solid fa-magnifying-glass"></i></button>
            </div>
            <div className='body-table body-table__modal table-responsive' style={{ maxHeight: `${maxHeight / 1.5}vh` }}>
                <div className="container">
                    <div className="body-table-wrapper mt-3" style={{ maxHeight: `${maxHeight / 2}vh` }}>
                        <table className="table table-bordered table-hover table-sm">
                            <thead className="table-dark sticky-top">
                                <tr>
                                    {columns.map((col) => (
                                        <th
                                            scope="col"
                                            key={col.id}
                                            style={{
                                                textAlign: col.align,
                                                width: `${col.width}px`,
                                                maxWidth: `${col.width}px`,
                                            }}
                                        >
                                            {col.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((row, idx) => {
                                    return (
                                        <tr key={idx}>
                                            {columns.map((col) => {
                                                const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                                return (
                                                    <td
                                                        key={col.id}
                                                        style={{
                                                            textAlign: col.align,
                                                            width: `${col.width}px`,
                                                            maxWidth: `${col.width}px`,
                                                        }}
                                                    >
                                                        {col.id === 'check' ?
                                                            <button type="button" className="btn btn-outline-dark btn-sm buttons-grid" onClick={() => setInfoWorker(row)}>
                                                                <i className="fa-regular fa-square-check"></i>
                                                            </button>
                                                            : (col.type === 'number' && col.format) ? col.format(value) : value
                                                        }
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <PaginationMain
                    size={'small'}
                    totalPages={totalPages}
                    page={page}
                    setPage={setPage}
                />
            </div>
        </div>
    )
}

export default SearchWorker;