import React, { } from "react";
import { CardHeader, CardContent, CardActions, } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { primary, tertiary, quaternary, } from '../../../assets/colors.js';
import { Modal, } from 'antd';

const ModalMain = (props) => {
    const { open, handleClose, title, subTitle, body, buttonActions, formSubmit, size, centrado } = props;

    return (
        <Modal
            title={
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: primary, fontSize: 'xx-small' }} aria-label="recipe">
                            <EngineeringIcon />
                        </Avatar>
                    }
                    title={title}
                    subheader={subTitle}
                    sx={{
                        paddingBottom: '0.3rem',
                        paddingTop: 0,
                        margin: '-0.5rem -1rem -0.1rem -1rem',
                        borderBottom: `solid 0.05rem ${tertiary}`,
                    }}
                />
            }
            open={open}
            onOk={handleClose}
            onCancel={handleClose}
            destroyOnClose={true}
            footer={null}

            style={{ top: (centrado ? '25%' : 70), border: `solid 0.15rem ${quaternary}`, borderRadius: '10px', paddingBottom: '0rem' }}
            width={(size === 'lg' ? 1000 : size === 'md' ? 800 : 500)}
        >
            <form onSubmit={formSubmit} >
                <CardContent
                    sx={{
                        padding: 0,
                        //margin: '0 -0.5rem',
                    }}
                >
                    {body}
                </CardContent>

                <CardActions
                    sx={{
                        //margin: '-0.5rem -1rem -0.1rem -1rem',
                        margin: '0.5rem -1rem -0.5rem -1rem',
                        padding: '0.5rem 1rem 0 1rem',
                        borderTop: `solid 0.05rem ${tertiary}`,
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    {buttonActions}
                    <button type="button" className="btn btn-danger" onClick={handleClose}>Cerrar</button>
                </CardActions>
            </form>
        </Modal >
    )
}

export default ModalMain;