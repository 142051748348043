import React from 'react';

const InfoMain = (props) => {
    const { title, titleColor, value, width, } = props;
    return (
        <div className="mb-1" style={{ width: (width || '100%') }}>
            <label htmlFor="basic-url" className={`color-text ${titleColor === 'black' ? 'color-text__dark' : 'color-text__primary'}`}>{title}</label>
            <div className="input-group">
                <label
                    style={{
                        border: 'solid 1px #C4C4C4',
                        width: (width || '100%'),
                        backgroundColor: '#F1EFEF',
                        borderRadius: '4px',
                        fontSize: '12px',
                        height: '2.05rem',
                        paddingLeft: 9,
                        display: 'flex',
                        alignItems: 'center',
                        color: '#000',
                        overflowY: 'auto'
                    }}
                >
                    {value || ''}
                </label>
            </div>
        </div>
    )
}

export default InfoMain;