import React, { useEffect, useState } from 'react';
import SeeDocuments from '../../pages/Charge/SeeDocuments';
import ChargeService from '../../../services/charge';
import { filterData } from '../../../utils/function';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';
import MainPagination from '../Main/PaginationMain';

const columns = [
    { id: 'id_cargo', label: '#', width: 30, align: 'center', },
    { id: 'fechaEntrega', label: 'Entrega', width: 60, align: 'center', },
    { id: 'numdoc', label: 'Documento', width: 65, align: 'left', },
    { id: 'trabajador', label: 'Trabajador', width: 120, align: 'left', },
    { id: 'equipo_id', label: 'Equipo', width: 120, align: 'left', },
    { id: 'nroSerie', label: 'Serial', width: 80, align: 'center', },
    { id: 'tipoCargo_id', label: 'Tipo Cargo', width: 80, align: 'left', },
    { id: 'ruta', label: 'Cargo', width: 40, align: 'center', },
    { id: 'actions', label: 'Acciones', width: 40, align: 'center', },
];
const columnsMap = {
    equipo_id: 'equipo.nombre',
    nroSerie: 'equipo.nroSerie',
    tipoCargo_id: 'tipoCargo.descripcion',
};
function getValueFromPath(obj, path) {
    const pathArray = path?.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodySearchCharge = ({ handleOpenLoad, handleCloseLoad, setTitleLoad, data, editCharge, search, totalPages, page, setPage, getCharge }) => {
    const url = window.location.pathname;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [urlDocument, setUrlDocument] = useState(null);

    useEffect(() => {
        getCharge();
    }, []);

    let resultSearch = filterData(data, search, ['numdoc', 'trabajador']);

    const deleteCharge = async (row) => {
        handleOpenLoad();
        setTitleLoad('Eliminando ...');
        try {
            const result = await ChargeService.deleteCharge(url, row?.id_cargo);
            if (result.status === CODES.DELETE_204) {
                simpleAlerts('Cargo eliminado', 'success');
                getCharge();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error al eliminar', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en deleteCharge: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    return (
        <div className='body-table table-responsive'>
            <SeeDocuments
                open={open}
                handleClose={handleClose}
                handleOpenLoad={handleOpenLoad}
                handleCloseLoad={handleCloseLoad}
                setTitleLoad={setTitleLoad}
                urlDocument={urlDocument}
            />
            <div className="container">
                <div className="body-table-wrapper mt-4">
                    <table className="table table-bordered table-hover">
                        <thead className="table-dark sticky-top">
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        scope="col"
                                        key={col.id}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`,
                                        }}
                                    >
                                        {col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {resultSearch?.map((row, idx) => {
                                return (
                                    <tr key={idx}>
                                        {columns.map((col) => {
                                            const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                            return (
                                                <td
                                                    key={col.id}
                                                    style={{
                                                        textAlign: col.align,
                                                        width: `${col.width}px`,
                                                        maxWidth: `${col.width}px`,
                                                    }}
                                                >
                                                    {col.id === 'actions' ?
                                                        <>
                                                            <button type="button" className="btn btn-outline-dark btn-sm mr-1 buttons-grid"
                                                                onClick={() => editCharge(row)}
                                                            >
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-outline-danger btn-sm mr-1 buttons-grid"
                                                                onClick={() => deleteCharge(row)}
                                                            >
                                                                <i className="fa-solid fa-trash"></i>
                                                            </button>
                                                        </> :
                                                        col.id === 'ruta' ?
                                                            <>
                                                                <button type="button" className="btn btn-outline-danger btn-sm mr-1 buttons-grid"
                                                                    title='Ver Cargo'
                                                                    onClick={() => { handleOpen(); setUrlDocument(row.ruta); }}
                                                                >
                                                                    <i className="fa-solid fa-file-pdf" style={{ fontSize: '1.05rem' }}></i>
                                                                </button>
                                                                {row.conformidad ?
                                                                    <button type="button" className="btn btn-outline-success btn-sm mr-1 buttons-grid"
                                                                        title='Ver Conformidad'
                                                                        onClick={() => { handleOpen(); setUrlDocument(row.conformidad); }}
                                                                    >
                                                                        <i className="fa-solid fa-file-circle-check" style={{ fontSize: '1.05rem' }}></i>
                                                                    </button>
                                                                    : <span className="badge badge-outlined-primary badge-btn" title='Falta subir conformidad'><i className="fa-regular fa-folder-open"></i></span>
                                                                }
                                                            </> :
                                                            (col.type === 'number' && col.format) ? col.format(value) : value
                                                    }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <MainPagination
                size={'small'}
                totalPages={totalPages}
                page={page}
                setPage={setPage}
            />
        </div>
    )
}

export default BodySearchCharge