import React from 'react';
import InfoMain from '../../Main/InfoMain';
import InputMain from '../../Main/InputMain';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const InfoGeneral = ({ worker, setWorker, searchDNI, DOC, setDOC }) => {
    return (
        <div className="container" style={{ maxHeight: '40vh', overflow: 'scroll' }}>
            <div className="row">
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    <div className='d-flex align-items-end justify-content-center'>
                        {worker?.id_externo ?
                            <InfoMain
                                title={'Documento'}
                                titleColor={'black'}
                                placeholder={'numero de documento'}
                                value={worker?.documento || ''}
                            />
                            : <>
                                <InputMain
                                    title={'Documento'}
                                    titleColor={'black'}
                                    placeholder={'documento'}
                                    value={DOC || ''}
                                    name={'documento'}
                                    onChange={(e) => setDOC(e.target.value)}
                                    required={true}
                                />
                                <button type="button" className="btn btn-outline-dark btn-sm mb-1 mx-1"
                                    title='Ver clave'
                                    onClick={searchDNI}
                                >
                                    <TravelExploreIcon sx={{ fontSize: '1.02rem' }} />
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Nombres'}
                        titleColor={'black'}
                        placeholder={'nombres'}
                        value={worker?.nombres || ''}
                        name={'nombres'}
                        onChange={(e) => setWorker({ ...worker, nombres: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Apellidos'}
                        titleColor={'black'}
                        placeholder={'apellidos'}
                        value={worker?.apellidos || ''}
                        name={'apellidos'}
                        onChange={(e) => setWorker({ ...worker, apellidos: e.target.value })}
                        required={true}
                    />
                </div>

                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Nro(s). Telefono'}
                        titleColor={'black'}
                        placeholder={'telefono'}
                        value={worker?.telefono || ''}
                        name={'telefono'}
                        onChange={(e) => setWorker({ ...worker, telefono: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Direccion'}
                        titleColor={'black'}
                        placeholder={'Dirección del proveedor'}
                        value={worker?.direccion || ''}
                        name={'direccion'}
                        onChange={(e) => setWorker({ ...worker, direccion: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Correo'}
                        type={'email'}
                        titleColor={'black'}
                        placeholder={'email'}
                        value={worker?.email || ''}
                        name={'email'}
                        onChange={(e) => setWorker({ ...worker, email: e.target.value })}
                        required={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoGeneral