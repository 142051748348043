import React  from "react"
import './cards.css'
function Card({title, imageSource , urlSource}){
    return(
        <div className="card text-center bg-dark animate__animated animate__fadeInUp">
            <div className="overflow">
                <img src={imageSource} alt="" className="card-img-top"/>
            </div>
            <div className="card-body text-light">
                <h4 className="card-title">{title}</h4>
                    <a href={urlSource} className="btn btn-outline-secondary rounded-0">
                      Ir a   
                    </a>
            </div>
        </div>
    )
}
export default Card
