const allowedTypesConformity = {
    image: ['image/jpeg', 'image/png'],
    pdf: ['application/pdf'],
    document: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    spreadsheet: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
};

const maxFileSizeConformityMB = {
    image: 5,
    pdf: 10,
    document: 10,
    spreadsheet: 10
};

const allowedTypesDevolution = {
    document: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    spreadsheet: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
};

export { allowedTypesConformity, maxFileSizeConformityMB, allowedTypesDevolution, };
