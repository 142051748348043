import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class BuyService {
    getInfoWorkerEquipment(url, id_persona) {
        let ruta = `${API_URL}/buy/info/worker-equipment?url=${url}&id_persona=${id_persona}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveBuyEquipment(url, body) {
        let ruta = `${API_URL}/buy/info/worker-equipment?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    uploadConformityBuy(url, body) {
        let ruta = `${API_URL}/buy/conformityBuy?url=${url}`;
        return axios.post(ruta, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...headerRequest(),
            },
        }).catch((error) => {
            return error;
        });
    }

    getCompraByUser(url) {
        let ruta = `${API_URL}/buy/user?url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new BuyService();
