import React from 'react';
import UserService from '../../../services/users';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';

const columns = [
    { id: 'id_usuario', label: '#', width: 25, align: 'center', },
    { id: 'usuario', label: 'Usuario', width: 70, align: 'center', },
    { id: 'nombres', label: 'Trabajador', width: 100, align: 'left', },
    { id: 'numdoc', label: 'Documento', width: 70, align: 'center', },
    { id: 'id_tipoUsuario', label: 'Tipo', width: 100, align: 'center', },
    { id: 'id_perfil', label: 'Perfil', width: 80, align: 'center', },
    { id: 'email', label: 'Email', width: 100, align: 'center', },
    { id: 'actions', label: 'Acciones', width: 50, align: 'center', },
];

const columnsMap = {
    id_perfil: 'perfil.desPerfil',
    id_tipoUsuario: 'tipoUsuario.destipoUsuario',
};

function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodyUser = ({ handleOpenLoad, handleCloseLoad, setTitleLoad, getProviders, data, search, viewUser }) => {
    const url = window.location.pathname;

    const deleteUsers = async (id) => {
        handleOpenLoad();
        setTitleLoad('Eliminando ...');
        try {
            const result = await UserService.deleteUsers(url, id);
            if (result.status === CODES.DELETE_204) {
                simpleAlerts(result?.data?.saveInfo?.message || 'Guardado', 'success');
                getProviders();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                handleCloseLoad();
                setTitleLoad(null);
            }
            return [];
        } catch (error) {
            console.log(`Error en saveProviders: ${error}`);
        }
    }

    return (
        <div className='body-table table-responsive'>
            <div className="container">
                <div className="body-table-wrapper mt-3">
                    <table className="table table-bordered table-hover table-sm">
                        <thead className="table-dark sticky-top">
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        scope="col"
                                        key={col.id}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`
                                        }}
                                    >
                                        {col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((row, idx) => {
                                return (
                                    <tr key={idx}
                                        className={`${row.estado.id_estado === 2 && 'table-secondary'}`}
                                    >
                                        {columns.map((col) => {
                                            const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                            return (
                                                <td
                                                    key={col.id}
                                                    style={{
                                                        textAlign: col.align,
                                                        width: `${col.width}px`,
                                                        maxWidth: `${col.width}px`,
                                                        overflowX: 'scroll',
                                                    }}
                                                >
                                                    {col.id === 'actions' ?
                                                        <>
                                                            <button type="button" className="btn btn-outline-dark btn-sm mr-1 buttons-grid" onClick={() => viewUser(row)}>
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-outline-danger btn-sm mr-1 buttons-grid" onClick={() =>
                                                                deleteUsers(row.id_usuario)}
                                                            >
                                                                <i className="fa-solid fa-trash"></i>
                                                            </button>
                                                        </>
                                                        : col.id === 'nombres' ?
                                                            (`${row.apellidos}, ${row.nombres}`)
                                                            : (col.type === 'number' && col.format) ? col.format(value) : value
                                                    }

                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BodyUser