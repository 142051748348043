import React from 'react';
import InfoMain from '../../Main/InfoMain';

const InfoGeneralAll = ({ informationAll, }) => {
    const equipo = informationAll;
    const detalleEquipo = informationAll?.detalleEquipo;
    const proveedor = informationAll?.proveedor;
    const categoria = informationAll?.categoria;
    const estadoEqp = informationAll?.estadoEqp;
    const tipoEquipo = informationAll?.tipoEquipo;

    const condicionEqp = informationAll?.condicionEqp;
    const codigoBarras = informationAll?.codigoBarras[0];
    const cargos = informationAll?.cargo?.filter(e => +e.estado.id_estado === 1)

    return (
        <div className="container" style={{}}>
            <div className="row mt-2">
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Código de barras'}
                        titleColor={'black'}
                        value={codigoBarras.codigoBarras}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Nombre'}
                        titleColor={'black'}
                        value={equipo.nombre}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Nombre'}
                        titleColor={'black'}
                        value={equipo.nombre}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Caracteristicas'}
                        titleColor={'black'}
                        value={equipo.caracteristicas}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Marca'}
                        titleColor={'black'}
                        value={equipo.marca}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Modelo'}
                        titleColor={'black'}
                        value={equipo.modelo}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Nro. Serie'}
                        titleColor={'black'}
                        value={equipo.nroSerie}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'IMEI'}
                        titleColor={'black'}
                        value={equipo.imei}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Valor'}
                        titleColor={'black'}
                        value={equipo.valor}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Empresa'}
                        titleColor={'black'}
                        value={equipo.desEmpresa}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'RUC'}
                        titleColor={'black'}
                        value={equipo.rucEmpresa}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Adquisición'}
                        titleColor={'black'}
                        value={equipo.fechaAdq}
                    />
                </div>
            </div>
            {detalleEquipo &&
                <>
                    <hr />
                    <div className="row mt-2">
                        {detalleEquipo?.map((info) => {
                            return (
                                < div className="col-md-4 col-sm-6" key={info.detalle}>
                                    <InfoMain
                                        title={info.detalle}
                                        titleColor={'black'}
                                        value={info.valor}
                                    />
                                </div>
                            );
                        })
                        }
                    </div>
                </>
            }
            <hr />
            <div className="row mt-2">
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Proveedor'}
                        titleColor={'black'}
                        value={proveedor.nombre}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Categoria'}
                        titleColor={'black'}
                        value={categoria.nombre}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Estado Eqp'}
                        titleColor={'black'}
                        value={estadoEqp.descripcion}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Tipo Eqp'}
                        titleColor={'black'}
                        value={tipoEquipo.desTipoEquipo}
                    />
                </div>
                <div className="col-md-4 col-sm-6">
                    <InfoMain
                        title={'Condición'}
                        titleColor={'black'}
                        value={condicionEqp.descripcion}
                    />
                </div>
            </div>
            {cargos?.map((cargo) => {
                const tipoCargo = cargo?.tipoCargo;
                const ciudad = cargo?.ciudad;
                const centroCosto = cargo?.centroCosto;
                const usuario = cargo?.usuario;
                return (
                    <>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Cargo'}
                                    titleColor={'black'}
                                    value={cargo?.desEmpresa}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Fecha entrega'}
                                    titleColor={'black'}
                                    value={cargo?.fechaEntrega}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Email'}
                                    titleColor={'black'}
                                    value={cargo?.email}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Telefono'}
                                    titleColor={'black'}
                                    value={cargo?.telefono}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Documento'}
                                    titleColor={'black'}
                                    value={cargo?.numdoc}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Trabajador'}
                                    titleColor={'black'}
                                    value={cargo?.trabajador}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Conformidad'}
                                    titleColor={'black'}
                                    value={cargo?.fechaConf}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Tipo cargo'}
                                    titleColor={'black'}
                                    value={tipoCargo?.descripcion}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Ciudad'}
                                    titleColor={'black'}
                                    value={ciudad.nombre}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'CECOS'}
                                    titleColor={'black'}
                                    value={centroCosto.names}
                                />
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <InfoMain
                                    title={'Usuario'}
                                    titleColor={'black'}
                                    value={usuario.usuario}
                                />
                            </div>
                        </div>
                    </>
                );
            })}
        </div >
    )
}

export default InfoGeneralAll;