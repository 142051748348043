import React, { useState, useEffect } from 'react'
import ModalMain from '../../../components/Main/ModalMain';
import InfoGeneral from '../../../components/WorkerExternal/AddWorkerExternal/InfoGeneral'
import ExternalService from '../../../../services/external';
import WorkerExternalService from '../../../../services/workerExternal';
import { CODES } from '../../../../utils/httpCodes';
import { simpleAlerts } from '../../../../utils/alerts';
import _ from 'lodash';

const AddWorkerExternal = (props) => {
    const { open, handleClose, setTitleLoad, handleOpenLoad, handleCloseLoad, worker, setWorker, getWorkerExternalAll, } = props;

    const url = window.location.pathname;
    const [DOC, setDOC] = useState(null);
    const [infoApi, setInfoApi] = useState(null);

    useEffect(() => {
        if (!infoApi) {
            return
        }
        let updWorker = _.cloneDeep(worker);
        updWorker.nombres = infoApi?.nombres;
        updWorker.apellidos = `${infoApi?.apellidoPaterno} ${infoApi?.apellidoMaterno}`;
        updWorker.documento = infoApi?.numeroDocumento;
        setWorker(updWorker);
    }, [infoApi]);

    const cleanData = () => {
        setWorker(null);
        setDOC(null);
        handleClose();
    }

    const searchDNI = async () => {
        try {
            setInfoApi(null);
            handleOpenLoad();
            setTitleLoad('Buscando...');
            const result = await ExternalService.apiDNI_SV(url, DOC);
            if (result.status === CODES.SUCCESS_200) {
                if (result.data?.error) {
                    simpleAlerts(result.data?.error, 'error');
                    return
                }
                if (!result.data?.nombre) {
                    simpleAlerts('No se encontro información!!');
                    return
                }
                setInfoApi(result.data);
            }
        } catch (error) {
            console.log(`Error en getProviders: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    };

    const saveProvider = async (e) => {
        e.preventDefault();

        handleOpenLoad();
        setTitleLoad('Guardando...');
        try {
            const result = await WorkerExternalService.saveWorkerExternal(url, worker);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.saveInfo?.message || 'Guardado', 'success');
                getWorkerExternalAll();
                handleClose();
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                handleCloseLoad();
                setTitleLoad(null);
            }
            return [];
        } catch (error) {
            handleCloseLoad();
            setTitleLoad(null);
            console.log(`Error en saveProviders: ${error}`);
        }
    }

    return (
        <ModalMain
            size={'sm'}
            open={open}
            handleClose={cleanData}
            formSubmit={saveProvider}
            title={'Mantenimiento de Trabajadores Externos'}
            subTitle={'Creación y edición de trabajadores'}
            body={
                <InfoGeneral
                    worker={worker}
                    setWorker={setWorker}
                    searchDNI={searchDNI}
                    DOC={DOC}
                    setDOC={setDOC}
                />
            }
            buttonActions={[
                <button type="submit" className="btn btn-primary" key={"btn-save-mdl"}>Guardar</button>
            ]}
        />
    );
}

export default AddWorkerExternal;