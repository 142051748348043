import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url_external;
const SYS = Global.sys_external;

class ExternalService {
    getWorker(url, { search = '', page = 1, pageSize = 20, pagination = '' }) {
        let ruta = `${API_URL}/worker?sys=${SYS}&url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    apiRUC(url, ruc) {
        let ruta = `${API_URL}/external/ruc?sys=${SYS}&url=${url}&ruc=${ruc}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    apiDNI_SV(url, dni) {
        let ruta = `${API_URL}/external/dni-sa?sys=${SYS}&url=${url}&dni=${dni}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getEmpresas() {
        let ruta = `${API_URL}/general/companies`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ExternalService();
