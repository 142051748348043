import React, { useState } from 'react';
import './login.scss';
import AuthService from '../../../services/auth';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import { simpleAlerts } from '../../../utils/alerts';
import { Global } from '../../../utils/general';
import { CODES } from '../../../utils/httpCodes';
import { signOut } from '../../../utils/function';
const infoStorage = Global.infoStorage;

const Login = ({ setUserToken }) => {
  const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
  const [openLoad, setOpenLoad] = useState(false);
  const handleOpenLoad = () => setOpenLoad(true);
  const handleCloseLoad = () => setOpenLoad(false);

  const signIn = async (e) => {
    e.preventDefault();
    try {
      handleOpenLoad();
      setTitleLoad('Cargando ...');
      let data = new FormData(e.currentTarget);
      const result = await AuthService.signIn({
        usuario: data.get('usuario'),
        clave: data.get('clave'),
      });
      if (result?.status === CODES.SUCCESS_200) {
        localStorage.setItem(infoStorage, JSON.stringify(result?.data));
        setUserToken(result?.data);
      } else if (result?.status === CODES.BAD_TOKEN_498) {
        signOut();
      } else if (result?.status === CODES.FORBIDDEN_403) {
        simpleAlerts(result?.data?.message, 'error');
      } else if (!result) {
        simpleAlerts('Problemas en el servidor, comuniquese con TI!', 'error');
      } else {
        // Handle other cases if needed
      }
    } catch (error) {
      console.log(`Error en signIn: ${error}`);
    } finally {
      handleCloseLoad();
      setTitleLoad(null);
    }
  };

  return (
    <div className='body-login'
      style={{
        background: "linear-gradient(180deg, rgba(23,41,77,1) 0%, rgba(42,64,126,1) 40%, rgba(60,89,156,1) 70%, rgba(79,113,185,1) 85%, rgba(98,136,214,1) 100%)",
      }}
    >
      <SpinnerLoad open={openLoad} title={titleLoad} />
      <div className="main-login">
        <input type="checkbox" id="chk-login" aria-hidden="true" />
        <div className="signup-login">
          <form onSubmit={signIn} >
            <label className='label-login' htmlFor="chk-login" aria-hidden="true">Login</label>
            <input className='input-login' type="text" name="usuario" pattern="[a-z]+"
              title="Ingrese solo letras (sin números ni caracteres especiales)"
              onInput={(e) => e.target.value = e.target.value.toLowerCase()}
              placeholder="Usuario" required />
            <input className='input-login' type="password" name="clave" placeholder="Password" required />
            <button className='button-login' type='submit'>Sign up</button>
          </form>
        </div>
        <div className="login">
          <form>
            <label className='label-login' htmlFor="chk-login" aria-hidden="true">Login</label>
            <input className='input-login' type="email" name="email" placeholder="Email" required="" />
            <input className='input-login' type="password" name="pswd" placeholder="Password" required="" />
            <button className='button-login'>Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
