import { Global } from './general';
const infoStorage = Global.infoStorage;

const signOut = function () {
    //const navigate = useNavigate()
    try {
        localStorage.removeItem(infoStorage);
        // setUserToken(null)
        // navigate("/sis-inv")
        window.location.reload();
        // const result = AuthService.signOut();
        // if (result.status === CODES.BAD_TOKEN_498 || result.status === CODES.SUCCESS_200) {
        //     localStorage.removeItem("infoAssisProd");
        //     window.location.reload();
        // }
    } catch (e) {
        console.log('error-signOut')
    }
}

const validateUser = function () {
    let user = localStorage.getItem(infoStorage);
    return user;
};

function filterData(data, searchValue, searchProperties) {
    return !searchValue ? data : data?.filter((dato) => {
        return searchProperties?.some(propiedad => {
            const propiedades = propiedad.split('.');
            let valor = dato;
            for (let i = 0; i < propiedades.length; i++)
                valor = valor?.[propiedades[i]];
            return valor?.toLowerCase().includes(searchValue.toLowerCase());
        });
    });
};

const getDateOL = function (date, type = 'short', moreDays = 0) {
    date = (date ? new Date(`${date}T00:00:00`) : new Date())
    let dateTmp = date;
    let firstDay = Number(new Date(dateTmp.getFullYear(), dateTmp.getMonth(), 1).getDate());
    let lastDay = Number(new Date(dateTmp.getFullYear(), dateTmp.getMonth() + 1, 0).getDate());

    let newDate;
    let fecha = date,
        hours = fecha.getHours(),
        minutes = fecha.getMinutes(),
        seconds = fecha.getSeconds(),
        day =
            (fecha.getDate() + moreDays) > lastDay ?
                lastDay :
                (fecha.getDate() + moreDays) < firstDay ?
                    firstDay :
                    (fecha.getDate() + moreDays)
        ,
        month = fecha.getMonth() + 1,
        year = fecha.getFullYear();

    if (type.toLowerCase() === 'short') {
        newDate = year + '-' + (month < 10 ? '0' + (month || '0') : month || '') + '-' + (day < 10 ? '0' + (day || '0') : day || '');
    }
    if (type.toLowerCase() === 'large') {
        newDate = (year + '-' + (month < 10 ? '0' + (month || '0') : month || '') + '-' + (day < 10 ? '0' + (day || '0') : day || '') + ' ' + hours + ':' + (minutes < 10 ? '0' + (minutes || '0') : minutes || '') + ':' + (seconds < 10 ? '0' + (seconds || '0') : seconds || ''));
    }
    return newDate;
};

const timeStampString = function () {
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toISOString().replace(/[-T:.Z]/g, ' ').split(' ').slice(0, -1).join(' '); //yyyy-mm-dd hh:mm:ss
    const mark = formattedDateTime.replace(/[-\s:]/g, '');//yyyymmddhhmmss
    return mark;
};

export { signOut, validateUser, filterData, getDateOL, timeStampString, };
