import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer, } from "react-toastify";
import './App.css';
import './assets/main.scss'
import { Global } from './utils/general';
import GeneralService from './services/general';
import BarHeader from './app/components/General/BarHeader';
import User from './app/pages/User/User';
import Category from './app/pages/Category/Category';
import Provider from './app/pages/Provider/Provider';
import Equipment from './app/pages/Equipment/Equipment';
import Charge from './app/pages/Charge/Charge';
import Conformity from './app/pages/Conformity/Conformity';
import Login from './app/pages/Login/Login';
import Home from './app/pages/Home';
import WorkerExternal from './app/pages/WorkerExternal/WorkerExternal';
import BuyEquipment from './app/pages/Buy/BuyEquipment';
import { validateUser } from './utils/function';
import { CODES } from './utils/httpCodes';
const infoStorage = Global.infoStorage;

function App() {
  const url = window.location.pathname;
  const [userToken, setUserToken] = useState(null);
  const [menu, setMenu] = useState(null);
  const pathInit = Global.path; //"/sis-inv";

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        if (!userToken) {
          setUserToken(JSON.parse(validateUser()));
        } else {
          const result = await GeneralService.getMenu();
          if (result?.status === CODES.SUCCESS_200) {
            // if (result?.data?.length === 0) {
            //   simpleAlerts('Lo sentimos el perfil asignado no tiene ningún permiso!', 'info');
            // }
            const menuData = result?.data?.map(element => ({
              label: element.descripcion,
              key: element.link,
            }));
            setMenu(menuData);
          } else if (result?.response?.status === CODES.BAD_TOKEN_498 || result?.message === 'Network Error') {
            //simpleAlerts((result?.response?.data.error || 'Sesión expirada'), 'error');
            localStorage.removeItem(infoStorage);
            setUserToken(null);
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(`Error en MenuService: ${error}`);
      }
    };

    fetchMenuData();
  }, [userToken]);

  const handleLoginSuccess = (data) => {
    setUserToken(data);
  };

  return (
    <div style={{ minHeight: '89vh' }}>
      <ToastContainer limit={3} />
      {userToken ? (
        <>
          {(url === '/') ? null : <BarHeader setUserToken={setUserToken} pathInit={pathInit} menu={menu} />}
          <div style={{ marginTop: 80 }}>
            <BodyWeb pathInit={pathInit} />
          </div>
        </>
      ) : (
        <Login setUserToken={handleLoginSuccess} />
      )}
    </div>
  );
}

function BodyWeb({ pathInit }) {
  return (
    <div>
      <Routes>
        <Route path={`${(pathInit || '/')}`} element={<Home />}></Route>
        <Route path={`${(pathInit || '/')}/inventory`} element={<Equipment />}></Route>
        <Route path={`${(pathInit || '/')}/documents`} element={<Charge />}></Route>
        <Route path={`${(pathInit || '/')}/conformity`} element={<Conformity />}></Route>
        <Route path={`${(pathInit || '/')}/providers`} element={<Provider />}></Route>
        <Route path={`${(pathInit || '/')}/category`} element={<Category />}></Route>
        <Route path={`${(pathInit || '/')}/users`} element={<User />}></Route>
        <Route path={`${(pathInit || '/')}/buy-equipment`} element={<BuyEquipment />}></Route>
        <Route path={`${(pathInit || '/')}/worker-external`} element={<WorkerExternal />}></Route>
      </Routes>
    </div>
  );
}

export default App;