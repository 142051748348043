import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class ProviderService {
    getProviders(url) {
        let ruta = `${API_URL}/providers?url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getProvidersAll(url) {
        let ruta = `${API_URL}/providers/all?url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveProviders(url, body) {
        let ruta = `${API_URL}/providers?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteProviders(url, id) {
        let ruta = `${API_URL}/providers/${id}?url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new ProviderService();
