import React, { useState, useEffect } from 'react'
import InfoMain from '../Main/InfoMain';
import InputMain from '../Main/InputMain';
import SelectMain from '../Main/SelectMain';
import SearchItems from '../../pages/Charge/SearchItems/SearchItems';
import ChargeService from '../../../services/charge';
import ExternalService from '../../../services/external';
import WorkerExternalService from '../../../services/workerExternal';
import { getDateOL } from '../../../utils/function';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';
import _ from 'lodash';

const newCharge = {
    "fechaEntrega": getDateOL(),
    "direccion": "",
    "email": "",
    "telefono": "",
    "persona_id": "",
    "nombres": "",
    "apellidos": "",
    "numdoc": "",
    "documento_id": "",
    "codSghh": "",
    "empresa_id": "",
    "ciudad": null,
    "centroCosto": null,
    "equipo": null,
    "tipoCargo": 1,
    "codigoOdoo": null,
    "estado": 1,
    "adicionales": null,
    "isExternal": 0,
}
const validationMessages = {
    fechaEntrega: 'Debe seleccionar la fecha de entrega!',
    ciudad: 'Debe seleccionar una ciudad!',
    direccion: 'Debe escribir una dirección!',
    persona_id: 'Debe seleccionar un trabajador!',
    empresa_id: 'Falta la empresa del trabajador!',
    centroCosto: 'Debe seleccionar un centro de costo!',
    equipo: 'Debe seleccionar un equipo!',
    tipoCargo: 'Debe seleccionar un tipo de cargo!',
    email: 'Debe ingresar un correo valido!',
};

const BodyGenerateCharge = (props) => {
    const { handleOpenLoad, handleCloseLoad, setTitleLoad, setActiveTab, isExternal, getCharge, edit, infoEdit, typesCharge, cities, centersCosts, } = props;
    const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const url = window.location.pathname;
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [datosCharge, setDatosCharge] = useState(edit === 1 ? newCharge : infoEdit);
    const [searchW, setSearchW] = useState(null);
    const [searchE, setSearchE] = useState(null);
    const [search, setSearch] = useState("");
    const [typeSearch, setTypeSearch] = useState(null);
    const [infoEquipment, setInfoEquipment] = useState(edit === 1 ? null : infoEdit?.equipo);
    const [infoWorker, setInfoWorker] = useState(edit === 1 ? null : infoEdit);
    const [companies, setCompanies] = useState(null);

    useEffect(() => {
        getEmpresas();
    }, []);
    useEffect(() => {
        let updCharge = _.cloneDeep(datosCharge);
        if (infoWorker) {
            updCharge.desPuesto = infoWorker?.desPuesto || 'EXTERNO';
            if (isExternal) {
                updCharge.trabajador = `${infoWorker?.apellidoPaterno || ''} ${infoWorker?.apellidoMaterno || ''}, ${infoWorker?.nombres || ''}` || (infoWorker?.nombre || '') || (infoWorker?.trabajador || '');
                updCharge.nombres = infoWorker?.nombres || '';
                updCharge.apellidos = `${infoWorker?.apellidoPaterno || ''} ${infoWorker?.apellidoMaterno || ''}`;
                updCharge.numdoc = infoWorker?.numeroDocumento;
                updCharge.direccion = infoWorker?.direccion;
            } else {
                updCharge.empresa_id = infoWorker?.id_empresa || infoWorker?.empresa_id || updCharge?.empresa_id;
                updCharge.desEmpresa = infoWorker?.desEmpresa || updCharge?.desEmpresa;
                updCharge.rucEmpresa = infoWorker?.rucEmpresa || updCharge?.rucEmpresa;
                updCharge.persona_id = infoWorker?.id_persona;
                updCharge.trabajador = infoWorker?.trabajador;
                updCharge.numdoc = infoWorker?.numdoc;
                updCharge.documento_id = infoWorker?.documento_id;
                updCharge.codSghh = infoWorker?.codSghh;
                updCharge.direccion = infoWorker?.direccion;
                updCharge.telefono = infoWorker?.telefono;
                updCharge.email = infoWorker?.email;
            }
        }
        setDatosCharge(updCharge);
    }, [infoWorker]);
    useEffect(() => {
        if (isExternal) {
            let updEquipment = _.cloneDeep(datosCharge);
            updEquipment.desEmpresa = companies?.find(e => +e.value === +datosCharge?.empresa_id)?.desEmpresa;
            updEquipment.rucEmpresa = companies?.find(e => +e.value === +datosCharge?.empresa_id)?.rucEmpresa;
            setDatosCharge(updEquipment);
        }
    }, [datosCharge?.empresa_id]);
    useEffect(() => {
        let updEquipment = _.cloneDeep(datosCharge);
        updEquipment.equipo = infoEquipment?.id_equipo || infoEquipment?.equipo?.id_equipo || updEquipment?.equipo;
        setDatosCharge(updEquipment);
    }, [infoEquipment]);

    const searchItem = (item) => {
        setTypeSearch(item);
        handleOpen();
        return setSearch(item === 1 ? searchW : searchE);
    }

    const getEmpresas = async () => {
        try {
            const result = await ExternalService.getEmpresas(url, searchW);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.value = element.id_empresa
                    element.label = element.desEmpresa
                })
                setCompanies(result.data);
            } else {
                simpleAlerts('No hay información de empresas', 'info');
            }
            return [];
        } catch (error) {
            console.log(`Error en getEmpresas: ${error}`);
        }
    }

    const getWorkerExternal = async () => {
        try {
            handleOpenLoad();
            setTitleLoad('Buscando trabajadores ...');
            const resultW = await WorkerExternalService.getWorkerExternal(url, datosCharge.numdoc);
            if (resultW.status === CODES.SUCCESS_200) {
                if (resultW?.data?.length > 0) {
                    let tmpData = resultW?.data[0];
                    tmpData.nombre = tmpData.apellidos + ', ' + tmpData.nombres;
                    tmpData.numeroDocumento = tmpData.documento;
                    tmpData.apellidoPaterno = tmpData.apellidos;
                    tmpData.nombres = tmpData.nombres;
                    setInfoWorker(tmpData)
                    let updDatosCharge = _.cloneDeep(datosCharge);
                    updDatosCharge.direccion = tmpData.direccion;
                    updDatosCharge.email = tmpData.email;
                    updDatosCharge.telefono = tmpData.telefono;
                    setDatosCharge(updDatosCharge);
                }
            }
            if (resultW?.data?.length === 0) {
                const result = await ExternalService.apiDNI_SV(url, datosCharge.numdoc);
                if (result.status === CODES.SUCCESS_200) {
                    setInfoWorker(result?.data)
                } else {
                    simpleAlerts(result.response.data.message, 'error');
                }
            }
            return [];
        } catch (error) {
            console.log(`Error en getWorker: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const saveCharge = async (e) => {
        e.preventDefault();
        if (!regexCorreo.test(datosCharge.email)) {
            simpleAlerts(validationMessages.email);
            return;
        }
        if (!datosCharge.fechaEntrega || datosCharge.fechaEntrega === '') {
            simpleAlerts(validationMessages.fechaEntrega);
            return;
        }
        if (!datosCharge.ciudad || datosCharge.ciudad === 0) {
            simpleAlerts(validationMessages.ciudad);
            return;
        }
        if (!datosCharge.direccion || datosCharge.direccion === '') {
            simpleAlerts(validationMessages.direccion);
            return;
        }
        if (!datosCharge.persona_id && !isExternal) {
            simpleAlerts(validationMessages.persona_id);
            return;
        }
        if (!datosCharge.empresa_id) {
            simpleAlerts(validationMessages.empresa_id);
            return;
        }
        if (!datosCharge.centroCosto || datosCharge.centroCosto === 0) {
            simpleAlerts(validationMessages.centroCosto);
            return;
        }
        if (!datosCharge.equipo) {
            simpleAlerts(validationMessages.equipo);
            return;
        }
        if (!datosCharge.tipoCargo || datosCharge.tipoCargo === 0) {
            simpleAlerts(validationMessages.tipoCargo);
            return;
        }
        handleOpenLoad();
        setTitleLoad('Guardando...');
        datosCharge.isExternal = isExternal ? 1 : 0;
        try {
            const result = await ChargeService.saveCharge(url, datosCharge);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                getCharge();
                setActiveTab(0);
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveCharge: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    return (
        <div className='row justify-content-center align-items-center'>
            <SearchItems
                open={open}
                handleClose={handleClose}
                handleOpenLoad={handleOpenLoad}
                handleCloseLoad={handleCloseLoad}
                setTitleLoad={setTitleLoad}

                typeSearch={typeSearch}
                setTypeSearch={setTypeSearch}
                search={search}
                setSearch={setSearch}

                infoWorker={infoWorker}
                setInfoWorker={setInfoWorker}
                infoEquipment={infoEquipment}
                setInfoEquipment={setInfoEquipment}
            />

            <div className='col-md-7 col-sm-12 border mt-2 body-main' style={{ height: '85vh' }}>
                <div className='row mx-2'>
                    <SelectMain
                        title={'Tipo de cargo'}
                        titleColor={'black'}
                        placeholder={'Seleccionar tipo de cargo'}
                        options={typesCharge}
                        value={datosCharge?.tipoCargo?.id_tipoCargo || datosCharge?.tipoCargo || ''}
                        onChange={(value, event) => setDatosCharge({ ...datosCharge, tipoCargo: value })}
                    />
                    <div className='col-md-6 col-sm-12'>
                        <InputMain
                            title={'Fecha de entraga'}
                            titleColor={'black'}
                            type={'date'}
                            value={datosCharge?.fechaEntrega || ''}
                            onChange={(e) => setDatosCharge({ ...datosCharge, fechaEntrega: e.target.value })}
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        {isExternal ?
                            <div className='d-flex align-items-end'>
                                <InputMain
                                    title={'Documento'}
                                    titleColor={'black'}
                                    placeholder={'Buscar por documento'}
                                    // value={searchW}
                                    // onChange={(e) => setSearchW(e.target.value)}
                                    value={datosCharge?.numdoc}
                                    onChange={(e) => setDatosCharge({ ...datosCharge, numdoc: e.target.value })}
                                />
                                <button type="button" className="btn btn-outline-dark btn-sm mb-1 btn-group-custom" onClick={getWorkerExternal}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                            : <div className='d-flex align-items-end'>
                                <InputMain
                                    title={'Trabajador'}
                                    titleColor={'black'}
                                    placeholder={'Buscar por documento'}
                                    value={searchW}
                                    onChange={(e) => setSearchW(e.target.value)}
                                />
                                <button type="button" className="btn btn-outline-dark btn-sm mb-1 btn-group-custom" onClick={() => searchItem(1)}><i className="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        }
                    </div>
                    {isExternal ?
                        <>
                            <div className='col-md-6 col-sm-12'>
                                <InputMain
                                    title={'Nombre'}
                                    titleColor={'black'}
                                    placeholder={'Nombre del trabajador'}
                                    value={datosCharge?.nombres || ''}
                                    onChange={(e) => setDatosCharge({ ...datosCharge, nombres: e.target.value })}
                                />
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <InputMain
                                    title={'Apellido'}
                                    titleColor={'black'}
                                    placeholder={'Apellido del trabajador'}
                                    value={datosCharge?.apellidos || ''}
                                    onChange={(e) => setDatosCharge({ ...datosCharge, apellidos: e.target.value })}
                                />
                            </div>
                        </>
                        : <div className='col-md-12 text-center pt-1'>
                            {infoWorker?.trabajador &&
                                <h6><span className="badge rounded-pill text-bg-dark">{infoWorker?.numdoc + ' - ' + infoWorker?.trabajador}</span></h6>
                            }
                        </div>
                    }
                    <div className='col-md-6 col-sm-12'>
                        {isExternal ?
                            <SelectMain
                                title={'Empresa'}
                                titleColor={'black'}
                                placeholder={'Seleccionar empresa'}
                                options={companies}
                                value={datosCharge?.empresa_id || ''}
                                onChange={(value, event) => setDatosCharge({ ...datosCharge, empresa_id: value })}
                            />
                            : <InfoMain
                                title={'Empresa'}
                                titleColor={'black'}
                                value={infoWorker?.desEmpresa}
                            />
                        }
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <InputMain
                            title={'Dirección'}
                            titleColor={'black'}
                            placeholder={'Dirección del trabajador'}
                            value={datosCharge?.direccion || ''}
                            onChange={(e) => setDatosCharge({ ...datosCharge, direccion: e.target.value })}
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <InputMain
                            title={'Telefono'}
                            titleColor={'black'}
                            placeholder={'Número de telefono'}
                            value={datosCharge?.telefono || ''}
                            onChange={(e) => setDatosCharge({ ...datosCharge, telefono: e.target.value })}
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <InputMain
                            title={'Correo'}
                            type={'email'}
                            titleColor={'black'}
                            placeholder={'correo electronico'}
                            value={datosCharge?.email || ''}
                            onChange={(e) => setDatosCharge({ ...datosCharge, email: e.target.value })}
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <SelectMain
                            title={'Ciudad'}
                            titleColor={'black'}
                            placeholder={'Seleccionar ciudad'}
                            options={cities}
                            value={datosCharge?.ciudad?.id_ciudad || datosCharge?.ciudad || ''}
                            onChange={(value, event) => setDatosCharge({ ...datosCharge, ciudad: value })}
                        />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <SelectMain
                            title={'Centro de costo'}
                            titleColor={'black'}
                            placeholder={'Seleccionar centro de costo'}
                            options={centersCosts}
                            value={datosCharge?.centroCosto?.id_centroCosto || datosCharge?.centroCosto || ''}
                            onChange={(value, event) => setDatosCharge({ ...datosCharge, centroCosto: value })}
                        />
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <div className='d-flex align-items-end'>
                            <InputMain
                                title={'Equipo'}
                                titleColor={'black'}
                                placeholder={'Buscar equipo (Nro. de serie)'}
                                value={searchE}
                                onChange={(e) => { setSearchE(e.target.value); setSearch(e.target.value); }}
                            />
                            <button type="button" className="btn btn-outline-dark btn-sm mb-1 btn-group-custom" onClick={() => searchItem(2)}><i className="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                        <InputMain
                            title={'Código activo fijo (ODOO)'}
                            titleColor={'black'}
                            placeholder={'Ingrese codigo de activo fijo'}
                            value={datosCharge?.codigoOdoo || ''}
                            onChange={(e) => setDatosCharge({ ...datosCharge, codigoOdoo: e.target.value })}
                        />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className="form-floating pt-3">
                            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea" style={{ height: '10rem', paddingTop: '12px', fontSize: '12px', letterSpacing: '1px' }} disabled
                                defaultValue=
                                {(infoEquipment &&
                                    "Nombre:\t\t\t" + infoEquipment?.nombre + "\n" +
                                    "Valor:\t\t\t" + parseFloat(infoEquipment?.valor)?.toFixed(2) + "\n" +
                                    "Marca:\t\t\t" + infoEquipment?.marca + "\n" +
                                    "Modelo:\t\t\t" + infoEquipment?.modelo + "\n" +
                                    "Nro Serie:\t\t" + infoEquipment?.nroSerie + "\n" +
                                    (infoEquipment?.imei ? "IMEI:\t\t\t" + infoEquipment?.imei + "\n" : "") +
                                    (infoEquipment?.categoria ? "Categoria:\t\t" + infoEquipment?.categoria?.nombre + "\n" : "") +
                                    (infoEquipment?.tipoEquipo ? "Tipo Equipo:\t\t" + infoEquipment?.tipoEquipo?.desTipoEquipo + "\n" : "") +
                                    (infoEquipment?.estadoEqp ? "Estado:\t\t\t" + infoEquipment?.estadoEqp?.descripcion + "\n" : "") +
                                    (infoEquipment?.proveedor ? "Proveedor:\t\t" + infoEquipment?.proveedor?.nombre + "\n" : "") +
                                    (infoEquipment?.caracteristicas ? "Caracteristicas:\t" + infoEquipment?.caracteristicas + "\n" : "")
                                )}
                            ></textarea>
                            <label htmlFor="floatingTextarea" style={{ color: 'black', fontSize: '12px' }} >Serial, nombre...</label>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className="form-floating pt-3">
                            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea" style={{ height: '10rem', paddingTop: '12px', fontSize: '12px', letterSpacing: '1px' }}
                                value={datosCharge?.adicionales || ''}
                                onChange={(e) => setDatosCharge({ ...datosCharge, adicionales: e.target.value })}
                            ></textarea>
                            <label htmlFor="floatingTextarea" style={{ color: 'black', fontSize: '12px' }} >Accesorios o adicionales del equipo</label>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <button type="submit" className="btn btn-success" onClick={saveCharge}>Generar Cargo</button>
                </div>
            </div>
        </div >
    )
}

export default BodyGenerateCharge