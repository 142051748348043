import React, { useState, useEffect } from 'react'
import ExternalService from '../../../../services/external';
import EquipmentService from '../../../../services/equipment';
import ModalMain from '../../../components/Main/ModalMain';
import SearchWorker from '../../../components/Charge/SearchItem/SearchWorker';
import SearchEquipment from '../../../components/Charge/SearchItem/SearchEquipment';
import { CODES } from '../../../../utils/httpCodes';
import { simpleAlerts } from '../../../../utils/alerts';

const SearchItems = (props) => {
    const {
        open, handleClose, handleOpenLoad, handleCloseLoad, setTitleLoad,
        typeSearch, setTypeSearch, search, setSearch,
        infoWorker, setInfoWorker, infoEquipment, setInfoEquipment,
    } = props;

    const url = window.location.pathname;
    const [dataWorkers, setDataWorkers] = useState(null);
    const [dataEquipment, setDataEquipment] = useState(null);
    const [page, setPage] = useState(1);
    const pageSize = 5;
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        if (typeSearch === 1)
            getWorker();
        if (typeSearch === 2)
            getEquipment();
    }, [typeSearch, page]);
    useEffect(() => {
        if (infoWorker && typeSearch === 1)
            cleanData();
    }, [infoWorker]);
    useEffect(() => {
        if (infoEquipment && typeSearch === 2)
            cleanData();
    }, [infoEquipment]);

    const cleanData = () => {
        return setTypeSearch(null), setDataWorkers(null), setDataEquipment(), setSearch(), handleClose();
    }

    const getWorker = async () => {
        if (typeSearch !== 1)
            return
        try {
            handleOpenLoad();
            setTitleLoad('Buscando trabajadores ...');
            const result = await ExternalService.getWorker(url, { search: (search || ''), page: page, pageSize: pageSize, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.data?.length === 0) {
                    simpleAlerts('No se encontro trabajador con la palabra clave!', 'error');
                }
                setDataWorkers(result?.data?.data);
                setTotalPages(result?.data?.last_page);
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getWorker: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const getEquipment = async () => {
        if (typeSearch !== 2)
            return
        try {
            handleOpenLoad();
            setTitleLoad('Buscando equipos ...');
            const result = await EquipmentService.getEquipment(url, {}, { search: (search || ''), page: page, pageSize: pageSize, pagination: 0 });
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.data?.length === 0) {
                    simpleAlerts('No se encontro información con la palabra clave!', 'error');
                }
                setDataEquipment(result?.data[0]);
                setTotalPages(result?.data[1]?.totalPages);
            } else {
                simpleAlerts('No se encontro equipo');
            }
            return []
        } catch (error) {
            console.log(`Error en getEquipment: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    return (
        <ModalMain
            size={'md'}
            open={open}
            handleClose={cleanData}
            title={`Busqueda de ${typeSearch === 1 ? 'trabajadores' : 'equipos'}`}
            subTitle={`Listado de ${typeSearch === 1 ? 'trabajadores' : 'equipos'}`}
            body={
                typeSearch === 1 ?
                    <SearchWorker
                        data={dataWorkers}
                        search={search}
                        setSearch={setSearch}
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        getWorker={getWorker}

                        setInfoWorker={setInfoWorker}
                    /> :
                    <SearchEquipment
                        data={dataEquipment}
                        search={search}
                        setSearch={setSearch}
                        page={page}
                        setPage={setPage}
                        totalPages={totalPages}
                        getEquipment={getEquipment}
                        setInfoEquipment={setInfoEquipment}
                    />
            }
            buttonActions={[]}
        />
    );
}

export default SearchItems;
