import React, { } from 'react';
import CategoryService from '../../../services/category';
import { filterData } from '../../../utils/function';
import { CODES } from '../../../utils/httpCodes';
import { simpleAlerts } from '../../../utils/alerts';
import _ from 'lodash';
import SelectMain from '../Main/SelectMain';

const tipo = [
    { value: "GTI", label: "Grupo Lavoro TI" },
    { value: "PTI", label: "Proveedor TI" }
]

const columns = [
    { id: 'id_categoria', label: '#', width: 20, align: 'center', },
    { id: 'nombre', label: 'Nombre', width: 150, align: 'left', },
    { id: 'codigoBarras', label: 'Codigo', width: 100, align: 'left', format: (value) => tipo?.find(e => e.value === value)?.label },
    { id: 'actions', label: 'Acciones', width: 40, align: 'center', },
];

const columnsMap = {
    estado_id: 'estado.desEstado',
};

function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodyCategory = ({ handleOpenLoad, handleCloseLoad, setTitleLoad, getCategory, data, setData, search, }) => {
    const url = window.location.pathname;
    let resultSearch = filterData(data, search, ['nombre']);

    const handleChange = (idx, key, value) => {
        let updData = _.cloneDeep(data);
        updData[idx][key] = value;
        return setData(updData);
    }

    const saveCategory = async (body, index) => {
        handleOpenLoad();
        setTitleLoad('Guardando ...');
        let updData = _.cloneDeep(data);
        try {
            const result = await CategoryService.saveCategory(url, body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.saveInfo?.message || 'Guardado', 'success');
                updData[index] = result?.data?.saveInfo;
                updData[index]['editable'] = 1;
                setData(updData);
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveCategory: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const deleteCategory = async (id, index) => {
        if (id) {
            try {
                handleOpenLoad();
                setTitleLoad('Eliminando ...');
                const result = await CategoryService.deleteCategory(url, id);
                if (result.status === CODES.DELETE_204) {
                    simpleAlerts('Eliminado', 'success');
                    getCategory();
                } else {
                    simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
                    handleCloseLoad();
                    setTitleLoad(null);
                }
                return [];
            } catch (error) {
                console.log(`Error en deleteCategory: ${error}`);
            }
        } else {
            let updData = _.cloneDeep(data);
            updData = updData?.filter((e, idx) => idx !== index);
            setData(updData);
        }
    }

    return (
        <div className='body-table table-responsive'>
            <div className="container">
                <div className="body-table-wrapper mt-3">
                    <table className="table table-bordered table-hover table-sm">
                        <thead className="table-dark sticky-top">
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        scope="col"
                                        key={col.id}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`,
                                        }}
                                    >
                                        {col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {resultSearch?.map((row, idx) => {
                                return (
                                    <tr key={idx}>
                                        {columns.map((col) => {
                                            const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                            return (
                                                <td
                                                    key={col.id}
                                                    style={{
                                                        textAlign: col.align,
                                                        width: `${col.width}px`,
                                                        maxWidth: `${col.width}px`,
                                                    }}
                                                >
                                                    {col.id === 'actions' ?
                                                        (row.editable === 1 ?
                                                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic mixed styles example">
                                                                <button type="button" className="btn btn-outline-dark btn-sm mr-1 buttons-grid" onClick={() => handleChange(idx, 'editable', 0)}>
                                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-outline-danger btn-sm mr-1 buttons-grid" onClick={() => deleteCategory(row.id_categoria, idx)}>
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </button>
                                                            </div>
                                                            :
                                                            <button type="button" className="btn btn-outline-warning btn-sm mr-1 buttons-grid"
                                                                onClick={() => saveCategory(row, idx)}>
                                                                <i className="fa-solid fa-floppy-disk"></i>
                                                            </button>
                                                        )
                                                        : row.editable === 0 ?
                                                            (col.id === 'nombre' ?
                                                                <div className="input-group input-group-sm mb-1">
                                                                    <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"
                                                                        value={value}
                                                                        onChange={(e) => handleChange(idx, col.id, e.target.value)}
                                                                    />
                                                                </div> :
                                                                col.id === 'codigoBarras' ?
                                                                    <SelectMain
                                                                        titleColor={'black'}
                                                                        width={'100%'}
                                                                        options={tipo}
                                                                        value={value}
                                                                        onChange={(value, event) => handleChange(idx, col.id, value)}
                                                                    />
                                                                    : null
                                                            )
                                                            : (col.format) ?
                                                                col.format(value)
                                                                : value
                                                    }

                                                </td>

                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BodyCategory