import React, { useState } from 'react';
import InputMain from '../../Main/InputMain';
import InfoMain from '../../Main/InfoMain';
import SelectMain from '../../Main/SelectMain';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const InfoGeneral = ({ state, profile, typeUser, user, setUser, }) => {
    const [viewPassword, setViewPassword] = useState(false);

    return (
        <div className="container-fluid" style={{ maxHeight: '40vh', overflow: 'scroll' }}>
            <div className="row">
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Usuario'}
                        titleColor={'black'}
                        placeholder={'Usuario'}
                        value={user?.usuario || ''}
                        name={'usuario'}
                        onChange={(e) => setUser({ ...user, usuario: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <div className='d-flex align-items-end justify-content-center'>
                        <InputMain
                            title={'Clave'}
                            titleColor={'black'}
                            placeholder={'minimo 8 caracteres, 1 numero, 1 mayusucula, 1 especial.'}
                            value={user?.clave || ''}
                            name={'clave'}
                            type={viewPassword ? 'text' : 'password'}
                            onChange={(e) => setUser({ ...user, clave: e.target.value })}
                            required={user?.id_usuario ? false : true}
                        />
                        <button type="button" className="btn btn-outline-dark btn-sm mb-1 mx-1"
                            title='Ver clave'
                            onClick={() => setViewPassword(!viewPassword)}
                        >
                            {viewPassword ?
                                <LockOpenIcon sx={{ fontSize: '1.02rem' }} />
                                : <LockPersonIcon sx={{ fontSize: '1.02rem' }} />
                            }
                        </button>
                    </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                    <SelectMain
                        title={'Pefil'}
                        titleColor={'black'}
                        placeholder={'Seleccionar estado'}
                        width={'100%'}
                        options={profile}
                        value={user?.perfil?.id_perfil || user?.perfil || 0}
                        name={'state'}
                        onChange={(value, e) => setUser({ ...user, perfil: value })}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InfoMain
                        title={'Nombre'}
                        titleColor={'black'}
                        placeholder={'Nombres trabajador'}
                        value={user?.nombres || ''}
                        name={'nombres'}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InfoMain
                        title={'Apellidos'}
                        titleColor={'black'}
                        placeholder={'Apellidos trabajador'}
                        value={user?.apellidos || ''}
                        name={'apellidos'}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InfoMain
                        title={'Documento'}
                        titleColor={'black'}
                        placeholder={'Documento del trabajador'}
                        value={user?.numdoc || ''}
                        name={'numdoc'}
                    />
                </div>
                <div className='col-sm-12 col-md-6'>
                    <InputMain
                        title={'Correo'}
                        titleColor={'black'}
                        placeholder={'Email del trabajador'}
                        value={user?.email || ''}
                        name={'email'}
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                        required={true}
                    />
                </div>

                <div className='col-sm-12 col-md-6'>
                    <SelectMain
                        title={'Estado'}
                        titleColor={'black'}
                        placeholder={'Seleccionar estado'}
                        width={'100%'}
                        options={state}
                        value={user?.estado?.id_estado || user?.estado || 0}
                        name={'state'}
                        onChange={(value, e) => setUser({ ...user, estado: value })}
                    />
                </div>
            </div>
        </div>
    )
}

export default InfoGeneral