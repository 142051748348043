import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import GeneralService from '../../../services/general';
import ChargeService from '../../../services/charge';
import TypeChargeService from '../../../services/type-charge';
import CityService from '../../../services/city';
import SpinnerLoad from '../../components/Main/SpinnerLoad/SpinnerLoad';
import BodySearchCharge from '../../components/Charge/BodySearchCharge';
import BodyGenerateCharge from '../../components/Charge/BodyGenerateCharge';
import InputMain from '../../components/Main/InputMain';
import { CODES } from '../../../utils/httpCodes';
import { pathRoute } from '../../../app/config/routesFiles';
import { simpleAlerts } from '../../../utils/alerts';
import { getDateOL } from '../../../utils/function';

const newCharge = {
    "fechaEntrega": getDateOL(),
    "direccion": "",
    "email": "",
    "telefono": "",
    "persona_id": "",
    "nombres": "",
    "apellidos": "",
    "numdoc": "",
    "documento_id": "",
    "codSghh": "",
    "empresa_id": "",
    "ciudad": null,
    "centroCosto": null,
    "equipo": null,
    "tipoCargo": 1,
    "codigoOdoo": null,
    "estado": 1,
    "adicionales": null,
    "isExternal": 0,
}

const Charge = () => {
    const url = window.location.pathname;
    const [titleLoad, setTitleLoad] = useState('Carga Inicial ...');
    const [openLoad, setOpenLoad] = useState(true);
    const handleOpenLoad = () => setOpenLoad(true);
    const handleCloseLoad = () => setOpenLoad(false);
    const [activeTab, setActiveTab] = useState(0);

    const [typesCharge, setTypesCarge] = useState(null);
    const [cities, setCities] = useState(null);
    const [centersCosts, setCentersCosts] = useState(null);
    const [charges, setCharges] = useState(null);
    const [infoEdit, setInfoEdit] = useState(null);
    const [edit, setEdit] = useState(0);
    const [search, setSearch] = useState(null);
    const [isExternal, setIsExternal] = useState(false);

    useEffect(() => {
        init();
        setInfoEdit(null);
    }, []);

    const init = async () => {
        Promise.all([
            getTypeCharge(),
            getCity(),
            getCostCenter(),
            getCharge()
        ]).then(() => { handleCloseLoad(); setTitleLoad(null); });
    }

    const handleTabClick = (tabIndex, event) => {
        event.preventDefault();
        setActiveTab(tabIndex);
        setEdit(tabIndex);
    };

    const getTypeCharge = async () => {
        try {
            const result = await TypeChargeService.getTypeCharge(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_tipoCargo
                    element.label = element.descripcion
                    element.value = element.id_tipoCargo
                })
                setTypesCarge(result.data);
            } else {
                simpleAlerts(result?.response?.data?.message || 'No se encontraron tipos de cargo', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeCharge: ${error}`);
        }
    }

    const getCity = async () => {
        try {
            const result = await CityService.getCity(url);
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_ciudad
                    element.label = element.nombre
                    element.value = element.id_ciudad
                })
                setCities(result.data);
            } else {
                simpleAlerts(result?.response?.data?.message || 'No se encontraron ciudades', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getCity: ${error}`);
        }
    }

    const getCostCenter = async () => {
        try {
            const result = await GeneralService.getCostCenter();
            if (result.status === CODES.SUCCESS_200) {
                result.data?.forEach(element => {
                    element.id = element.id_centroCosto
                    element.label = element.names
                    element.value = element.id_centroCosto
                })
                setCentersCosts(result.data);
            } else {
                simpleAlerts(result?.response?.data?.message || 'No se encontraron centros de costos', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getCostCenter: ${error}`);
        }
    }

    useEffect(() => {
        setCharges(null);
        setTotalPages(1);
    }, [search]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 9;
    useEffect(() => {
        getCharge();
    }, [page]);
    const getCharge = async () => {
        try {
            handleOpenLoad();
            setTitleLoad("Buscando cargos ...");
            const params = {
                page: page,
                pageSize: pageSize,
                search: search,
            };
            const result = await ChargeService.getCharge(url, params);
            if (result.status === CODES.SUCCESS_200) {
                let dataTmp = result.data[0];
                dataTmp?.forEach(element => {
                    element.ruta = (element.ruta ? pathRoute.path + element.ruta : element.ruta);
                    element.conformidad = (element.conformidad ? pathRoute.path + element.conformidad : element.conformidad);
                })
                setCharges(dataTmp);
                setTotalPages(result.data[1]?.totalPages);
            } else {
                simpleAlerts(result?.response?.data?.message || 'No se encontraron centros de costos', 'info');
            }
            return []
        } catch (error) {
            console.log(`Error en getCostCenter: ${error}`);
        } finally {
            handleCloseLoad();
            setTitleLoad(null);
        }
    }

    const editCharge = (info) => {
        if (info.conformidad) {
            simpleAlerts("No se puede editar. !Ya tiene un conformidad?", "warning");
            return;
        }
        if (info?.externos) {
            info.nombres = info.externos.nombres
            info.apellidos = info.externos.apellidos
            info.numdoc = info.externos.documento
        }
        setIsExternal(info?.externos ? true : false);
        setInfoEdit(info);
        setActiveTab(1);
    }

    const handleCheckboxExternal = (event) => {
        setInfoEdit(newCharge);
        setIsExternal(event.target.checked);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            getCharge();
        }
    };

    return (
        <div>
            <ul className="nav nav-tabs nav-tabs-charge justify-content-center">
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                        onClick={(e) => handleTabClick(0, e)}
                        href="#"
                    >
                        Buscar cargos
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                        onClick={(e) => handleTabClick(1, e)}
                        href="#"
                    >
                        Generar cargos
                    </a>
                </li>
            </ul>
            {activeTab === 0 ?
                <div className='container' style={{ marginTop: '0.5rem' }}>
                    <div className='animate__animated animate__backInLeft'>
                        <div className='d-flex justify-content-center align-items-end'>
                            <InputMain
                                title={'Buscar cargos'}
                                placeholder={'nombre del trabajador, documento'}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                width={'95%'}
                                onKeyPress={handleKeyPress}
                            />
                            <button
                                type="button"
                                className="btn btn-outline-warning btn-group-custom p-2"
                                onClick={getCharge}>
                                <SearchIcon fontSize='small'/>
                            </button>
                        </div>
                        <BodySearchCharge
                            handleOpenLoad={handleOpenLoad}
                            handleCloseLoad={handleCloseLoad}
                            setTitleLoad={setTitleLoad}
                            data={charges}
                            editCharge={editCharge}
                            getCharge={getCharge}
                            search={search}
                            totalPages={totalPages}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                </div> :
                <div className='container' style={{ marginTop: '0.5rem' }}>
                    <div className='animate__animated animate__backInDown'>
                        <div
                            style={{
                                position: "absolute",
                                top: "9px",
                                right: window.innerWidth < 768 ? "1%" : "21%",
                                zIndex: 1,
                            }}
                        >
                            <input type="checkbox" className="btn-check" id="btn-check-outlined" checked={isExternal} onChange={handleCheckboxExternal} />
                            <label className="btn btn-outline-danger btn-sm" htmlFor="btn-check-outlined">Externo</label>
                        </div>
                        <BodyGenerateCharge
                            handleOpenLoad={handleOpenLoad}
                            handleCloseLoad={handleCloseLoad}
                            setTitleLoad={setTitleLoad}
                            setActiveTab={setActiveTab}

                            isExternal={isExternal}
                            getCharge={getCharge}
                            edit={edit}
                            infoEdit={infoEdit}
                            typesCharge={typesCharge}
                            cities={cities}
                            centersCosts={centersCosts}
                        />
                        {/* {isExternal ?
                            <BodyGenerateChargeExternal
                                handleOpenLoad={handleOpenLoad}
                                handleCloseLoad={handleCloseLoad}
                                setTitleLoad={setTitleLoad}
                                setActiveTab={setActiveTab}

                                isExternal={isExternal}
                                getCharge={getCharge}
                                edit={edit}
                                infoEdit={infoEdit}
                                typesCharge={typesCharge}
                                cities={cities}
                                centersCosts={centersCosts}
                            />
                            : <BodyGenerateCharge
                                handleOpenLoad={handleOpenLoad}
                                handleCloseLoad={handleCloseLoad}
                                setTitleLoad={setTitleLoad}
                                setActiveTab={setActiveTab}

                                isExternal={isExternal}
                                getCharge={getCharge}
                                edit={edit}
                                infoEdit={infoEdit}
                                typesCharge={typesCharge}
                                cities={cities}
                                centersCosts={centersCosts}
                            />
                        } */}
                    </div>
                </div>
            }
            <SpinnerLoad open={openLoad} title={titleLoad} />
        </div>
    );
};

export default Charge