import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class EquipmentService {
    getEquipment(url, { empresa_id = -1, tipoEquipo = -1, categoria = -1, }, { search = '', page = 1, pageSize = 20, pagination = '' }) {
        const ruta = `${API_URL}/equipment?url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}&empresa_id=${empresa_id}&tipoEquipo=${tipoEquipo}&categoria=${categoria}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    getEquipmentBydIdInfo(url, id) {
        const ruta = `${API_URL}/equipment/info/${id}?url=${url}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveEquipment(url, body) {
        const ruta = `${API_URL}/equipment?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteEquipment(url, id) {
        const ruta = `${API_URL}/equipment/${id}?url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    generarCodigoBarras(url, body = {}) {
        const ruta = `${API_URL}/equipment/generar/codigo-barras?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });

    }

}

export default new EquipmentService();
