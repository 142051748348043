import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { simpleAlerts } from '../../../utils/alerts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PreviewIcon from '@mui/icons-material/Preview';
import { secondary, tertiary } from '../../../assets/colors';
import MainPagination from '../Main/PaginationMain';
import { pathRoute } from '../../../app/config/routesFiles';
import BuyService from '../../../services/buy';
import { CODES } from '../../../utils/httpCodes';
import _ from 'lodash';

const columns = [
    { id: '#', label: '#', width: 30, align: 'center', },
    { id: 'numdoc', label: 'Documento', width: 100, align: 'left', },
    { id: 'trabajador', label: 'Trabajador', width: 500, align: 'left', },
    { id: 'actions', label: 'Acc.', width: 50, align: 'left', },
];
const columnsMap = {
    // equipo_id: 'equipo.nombre',
    // tipoCargo_id: 'tipoCargo.descripcion',
};
function getValueFromPath(obj, path) {
    const pathArray = path.split('.');
    let value = obj;
    for (const key of pathArray) {
        if (value === null || value === undefined) {
            return ''; // O algún valor por defecto
        }
        value = value[key];
    }
    return value;
}

const BodyBuyEquipment = ({ data, setData, page, setPage, totalPages, buyEquipment, infoEquipments, listEquipment, setTitleLoad, handleOpenLoad, handleCloseLoad }) => {

    const handleChange = async (info, key, value) => {
        info[key] = value;
        const newData = data.map((element) => {
            // Si el id del elemento coincide con el id del item, actualiza el nombre
            if (element.id === info.id_persona) {
                return { ...element, id_persona: info.id_persona };
            }
            // Si no coincide, simplemente devuelve el elemento sin cambios
            return element;
        });
        return setData(newData);
    }

    return (
        <div className='body-table table-responsive'>
            <div className="container">
                <div className="body-table-wrapper mt-4">
                    <table className="table table-bordered table-hover">
                        <thead className="table-dark sticky-top">
                            <tr>
                                {columns.map((col) => (
                                    <th
                                        scope="col"
                                        key={`col-${col.id}`}
                                        style={{
                                            textAlign: col.align,
                                            width: `${col.width}px`,
                                            maxWidth: `${col.width}px`,
                                            paddingTop: '3px',
                                            paddingBottom: '3px',
                                        }}
                                    >
                                        {col.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((row, idx) => {
                                return (
                                    <React.Fragment key={`row-fragment-${idx}`}>
                                        <tr key={`row-b-${idx}`}>
                                            {columns.map((col) => {
                                                const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                                return (
                                                    <td
                                                        key={`col-b-${col.id}`}
                                                        style={{
                                                            textAlign: col.align,
                                                            width: `${col.width}px`,
                                                            maxWidth: `${col.width}px`,
                                                            paddingTop: '3px',
                                                            paddingBottom: '3px',
                                                        }}
                                                    >
                                                        {col.id === '#' ?
                                                            (idx + 1) :
                                                            col.id === 'actions' ?
                                                                <button type="button" className="btn btn-outline-dark btn-sm buttons-grid"
                                                                    onClick={() => {
                                                                        !row.viewEquipment ?
                                                                            listEquipment(row)
                                                                            : handleChange(row, 'viewEquipment', false)
                                                                    }
                                                                    }
                                                                >
                                                                    {row.viewEquipment ?
                                                                        <ArrowDropUpIcon fontSize='small' style={{ padding: 0 }} />
                                                                        : <ArrowDropDownIcon fontSize='small' style={{ padding: 0 }} />
                                                                    }
                                                                </button> :
                                                                (col.type === 'number' && col.format) ? col.format(value) : value
                                                        }
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {(infoEquipments?.length > 0 && row.viewEquipment) &&
                                            <tr style={{ border: `solid 2px ${tertiary}` }}>
                                                <td colSpan={columns?.length}>
                                                    {infoEquipments?.map((info, infoIdx) => {
                                                        return (
                                                            <DatosEquipo
                                                                key={`infoIdx-${infoIdx}`}
                                                                worker={row}
                                                                info={info}
                                                                buyEquipment={buyEquipment}
                                                                setTitleLoad={setTitleLoad}
                                                                handleOpenLoad={handleOpenLoad}
                                                                handleCloseLoad={handleCloseLoad}
                                                                listEquipment={listEquipment}

                                                            />
                                                        )
                                                    })}
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <MainPagination
                totalPages={totalPages}
                page={page}
                setPage={setPage}
            />
        </div>
    )
}

const DatosEquipo = ({ worker, info, buyEquipment, setTitleLoad, handleOpenLoad, handleCloseLoad, listEquipment }) => {

    const [fileInputRef, setFileInputRef] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);

    const url = window.location.pathname;

    let compra = _.cloneDeep(info?.compra[0]);
    if (compra) {
        compra.ruta01 = (info?.compra[0]?.ruta01 ? pathRoute.path + info?.compra[0]?.ruta01 : '');
        compra.ruta02 = (info?.compra[0]?.ruta02 ? pathRoute.path + info?.compra[0]?.ruta02 : '');
        compra.conf01 = (info?.compra[0]?.conf01 ? pathRoute.path + info?.compra[0]?.conf01 : '');
        // compra.conf02 = (info?.compra[0]?.conf02 ? pathRoute.path + info?.compra[0]?.conf02 : '');
    }

    const handleDownloadClick = (url, nombre) => {
        if (!url) {
            simpleAlerts("No tiene ningun archivo para descargar");
            return;
        }
        const array = url?.split('.');
        const extention = array[array.length - 1];
        const link = document.createElement('a');
        link.href = url;
        link.download = `${nombre}.${extention}`;
        link.target = '_blank';
        link.click();
    };

    const saveCharge = async (info, selectedFile) => {
        try {
            handleOpenLoad();
            setTitleLoad('Guardando...');
            const array = selectedFile.name.split('.');
            const ext = array[array?.length - 1];
            const currentDateTime = new Date();
            const formattedDateTime = currentDateTime.toISOString().replace(/[-T:.Z]/g, ' ').split(' ').slice(0, -1).join(' '); // yyyy-mm-dd hh:mm:ss
            const mark = formattedDateTime.replace(/[-\s:]/g, '');// yyyymmddhhmmss

            const nameFile = `${info?.numdoc}${mark}-conf.${ext}`;
            const formData = new FormData();
            const modifiedFile = new File([selectedFile], nameFile, { type: selectedFile.type });
            formData.append('myFile', modifiedFile);
            formData.append('id_compra', info.compra[0].id_compra);
            formData.append('nameFile', nameFile);


            const result = await BuyService.uploadConformityBuy(url, formData);
            if (result.status === CODES.SUCCESS_200) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveCharge: ${error}`);
        } finally {
            listEquipment(worker);
            handleCloseLoad();
            setTitleLoad(null);
        }
    };

    const handleFileUpload = () => {
        if (fileInputRef && fileInputRef.files.length > 0) {
            const selectedFile = fileInputRef.files[0];
            // Actualizar el estado para mostrar el nombre del archivo
            setSelectedFileName(selectedFile.name);
            // Actualizar el estado para indicar que se ha subido un archivo
            setIsFileUploaded(true);
            saveCharge(info, selectedFile);
        } else {
            console.log('Ningún archivo seleccionado.');
        }
    };

    return (
        <>
            <hr style={{ marginTop: 8, marginBottom: 8, }} />
            <div className='row'
                style={{ border: `solid 0.5px ${secondary}`, borderRadius: '25px', marginLeft: '1px', marginRight: '1px', }}
            >
                <div className='col-md-5 d-flex justify-content-evenly align-items-center'>
                    {info?.conformidad ?
                        <button className='btn btn-sm btn-outline-primary'
                            onClick={() => handleDownloadClick(info.conformidad, 'Conformidad')}
                            title='Conformidad del equipo'
                        >
                            <i className="fa-solid fa-file-signature"></i>
                        </button>
                        : <button className='btn btn-sm btn-outline-dark'
                            onClick={() => handleDownloadClick(info.ruta, 'Cargo')}
                            title='Cargo del equipo'
                        >
                            <i className="fa-solid fa-file-invoice"></i>
                        </button>
                    }
                    {compra ?
                        (!compra?.conf01 &&
                            <>
                                <button className='btn btn-sm btn-outline-dark'
                                    title='Formato 01'
                                    onClick={() => handleDownloadClick(compra.ruta01, 'Formato_01')}
                                >
                                    <i className="fa-regular fa-address-book"></i>
                                </button>
                                <button className='btn btn-sm btn-outline-dark'
                                    title='Formato 02'
                                    onClick={() => handleDownloadClick(compra.ruta02, 'Formato_02')}
                                >
                                    <i className="fa-regular fa-address-book"></i>
                                </button>
                            </>)
                        : <button className='btn btn-sm btn-outline-success'
                            title='Comprar equipo'
                            onClick={() => buyEquipment(worker, info)}
                        >
                            <i className="fa-solid fa-cart-shopping"></i>
                        </button>
                    }
                    {compra?.conf01 ?
                        <button className="btn btn-outline-primary btn-sm" title='Vista Previa' onClick={() => handleDownloadClick(compra.conf01, 'Vista_Previa')}>
                            <PreviewIcon />
                        </button>
                        : ((compra?.ruta01 && compra?.ruta02) ?
                            <button className="btn btn-outline-danger btn-sm" title='Subir Archivo' onClick={() => fileInputRef.click()}>
                                <CloudUploadIcon />
                                <input
                                    type="file"
                                    accept=".pdf, .doc, .docx"
                                    style={{ display: 'none' }}
                                    ref={(input) => setFileInputRef(input)}
                                    onChange={() => handleFileUpload(compra.id_compra)}
                                />
                            </button>
                            : null
                        )}
                </div>
                <div className='col-md-2'>
                    <InformationElement
                        title={'Fec. Entrega'}
                        value={info.fechaEntrega}
                    />
                </div>
                <div className='col-md-2'>
                    <InformationElement
                        title={'Fec. Confor.'}
                        value={info.fechaConf}
                    />
                </div>
                <div className='col-md-2'>
                    <InformationElement
                        title={'Valor'}
                        value={info.equipo.valor?.toFixed(2)}
                    />
                </div>

                <div className='col-md-4'>
                    <InformationElement
                        title={'Nombre'}
                        value={info.equipo.nombre}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'#Serie'}
                        value={info.equipo.nroSerie}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'IMEI'}
                        value={info.equipo.imei}
                    />
                </div>

                <div className='col-md-4'>
                    <InformationElement
                        title={'Fecha Adq.'}
                        value={info.equipo.fechaAdq}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'Marca'}
                        value={info.equipo.marca}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'Modelo'}
                        value={info.equipo.modelo}
                    />
                </div>

                <div className='col-md-6'>
                    <InformationElement
                        title={'Adicionales'}
                        value={info.adicionales}
                    />
                </div>
                <div className='col-md-6'>
                    <InformationElement
                        title={'Caracteristicas'}
                        value={info.equipo.caracteristicas}
                    />
                </div>

                <div className='col-md-4'>
                    <InformationElement
                        title={'Estado'}
                        value={info.equipo.estado.desEstado}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'Estado Eqp'}
                        value={info.equipo.estadoEqp.descripcion}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'Tipo Eqp'}
                        value={info.equipo.tipoEquipo.desTipoEquipo}
                    />
                </div>

                <div className='col-md-4'>
                    <InformationElement
                        title={'Categoria'}
                        value={info.equipo.categoria.nombre}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'Condición'}
                        value={info.equipo.condicionEqp.descripcion}
                    />
                </div>
                <div className='col-md-4'>
                    <InformationElement
                        title={'Proveedor'}
                        value={info.equipo.proveedor.nombre}
                    />
                </div>
            </div>
        </>
    )
}

const InformationElement = (props) => {
    const { title, value } = props;
    return (
        <div className="mb-1" style={{ width: '100%' }}>
            <span
                className="badge rounded-pill text-dark border border-dark"
                style={{ padding: '2px 5px', fontSize: '10px' }}
            >
                {title}
            </span>
            <label
                style={{
                    border: 'solid 1px #C4C4C4',
                    borderRadius: '4px',
                    fontSize: '11px',
                    height: '1.25rem',
                    marginTop: 2,
                    paddingLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                }}
            >
                {value}
            </label>
        </div>
    )
}

export default BodyBuyEquipment