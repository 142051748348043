import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = Global.url;

class UsersService {
    getUsers(url, { search = '', page = 1, pageSize = 20, pagination = '' }) {
        let ruta = `${API_URL}/users?url=${url}&search=${search}&page=${page}&pageSize=${pageSize}&pagination=${pagination}`;
        return axios.get(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    saveUser(url, body) {
        let ruta = `${API_URL}/users?url=${url}`;
        return axios.post(ruta, body, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }

    deleteUsers(url, id) {
        let ruta = `${API_URL}/users/${id}?url=${url}`;
        return axios.delete(ruta, {
            headers: headerRequest(),
        }).catch((error) => {
            return error;
        });
    }
}

export default new UsersService();
