import React from 'react';
import TextField from '@mui/material/TextField';

const InputMain = (props) => {
    const { title, titleColor, readonly, placeholder, type, value, required, name, onChange, style, width, propsInput, onKeyPress } = props;
    return (
        <div className="mb-1" style={{ width: (width || '100%') }}>
            <label htmlFor="basic-url" className={`color-text ${titleColor === 'black' ? 'color-text__dark' : 'color-text__primary'}`}>{title}</label>
            <div className="input-group">
                <TextField
                    className="text"
                    fullWidth
                    required={required || false}

                    placeholder={placeholder || ''}
                    size='small'
                    type={type || 'text'}
                    value={value || ''}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    name={name || ''}

                    style={{ ...style, borderRadius: '10px' }}
                    inputProps={{
                        ...propsInput,
                        style: {
                            fontSize: 12,  // font size of input text
                            backgroundColor: readonly ? '#F1EFEF' : 'white',
                            borderRadius: '10px'
                        },
                        readOnly: (readonly || false),
                    }}

                    InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                />
            </div>
        </div>
    )
}

export default InputMain;